<template>
  <div class="rounded border p-3 my-3">
    <h5>{{ cpoSettingsLabel }}</h5>
    <div class="row mt-3">
      <div class="col-4">
        <cg-check
          :label="hubjectEnabledLabel"
          form-name="hubjet_enabled"
          :value="hubjectEnabled"
          @cg-input="(newValue) => (hubjectEnabled = newValue)"
        ></cg-check>
      </div>
      <div class="col-4">
        <cg-check :label="ocpiEnabledLabel" form-name="ocpi_enabled" :value="ocpiEnabled"></cg-check>
      </div>
      <div class="col-4">
        <cg-check
          :label="internalRoamingEnabledLabel"
          form-name="internal_roaming_enabled"
          :value="internalRoamingEnabled"
        ></cg-check>
      </div>
    </div>
  </div>

  <div class="rounded border p-3 mt-3" :class="{ 'border-danger': isHubjectMissingError || isHotlineError }">
    <h5>{{ hubjectSettingsLabel }}</h5>
    <input v-if="componentError" required style="height: 0; padding: 0; border: none; display: flex" />
    <cg-input
      form-name="hubjet_prefix"
      :label="hubjectPrefixLabel"
      :disabled="!hubjectEnabled || (!!hubjectParentCpoId && isSameEmpParent)"
      :value="hubjectPrefix"
      @cg-input="(newValue) => (hubjectPrefix = newValue)"
    >
    </cg-input>

    <cg-select
      v-if="hubjectParents"
      form-name="hubjet_parent_cpo_id"
      :label="hubjectParentCpoIdLabel"
      :disabled="!hubjectEnabled || (!!hubjectPrefix && isSameEmpParent)"
      :options="hubjectParents"
      :selected="hubjectParentCpoId"
      @cg-input="(newValue) => (hubjectParentCpoId = newValue)"
    >
    </cg-select>

    <cg-input
      form-name="hubjet_hotline"
      :label="hubjectHotlineLabel"
      :disabled="!hubjectEnabled"
      :pattern="hubjectHotlinePattern"
      :value="hubjectHotline"
      @cg-input="(newValue) => (hubjectHotline = newValue)"
    >
    </cg-input>
  </div>
  <span
    class="invalid-feedback"
    style="display: block"
    v-if="isHubjectMissingError"
    v-text="'The Prefix or provider needs to be selected if the Hubjet is checked.'"
  ></span>
  <span
    class="invalid-feedback"
    style="display: block"
    v-if="isHotlineError"
    v-text="'When hubject parent cpo is not entered, hotline number must be present.'"
  ></span>
  <div class="mb-3"></div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';

const props = defineProps({
  hubjectEnabled: Boolean,
  ocpiEnabled: Boolean,
  internalRoamingEnabled: Boolean,
  hubjectPrefix: String,
  hubjectParentCpoId: String,
  hubjectHotline: String,
  hubjectHotlinePattern: String,
  hubjectParents: Array,
  hubjectEnabledLabel: String,
  ocpiEnabledLabel: String,
  internalRoamingEnabledLabel: String,
  hubjectPrefixLabel: String,
  hubjectParentCpoIdLabel: String,
  hubjectHotlineLabel: String,
  cpoSettingsLabel: String,
  hubjectSettingsLabel: String,
  hubjectParentsFull: Array,
  empId: Number,
});

const hubjectEnabled = ref(props.hubjectEnabled);
const hubjectPrefix = ref(props.hubjectPrefix);
const hubjectParentCpoId = ref(props.hubjectParentCpoId);
const hubjectHotline = ref(props.hubjectHotline);
const isValidating = inject('isValidating', false);
const parentHash = props.hubjectParentsFull.reduce((acc, parent) => {
  acc[parent.id] = parent;
  return acc;
}, {});

watch(hubjectPrefix, (newVal) => {
  if (newVal && isSameEmpParent.value) {
    hubjectParentCpoId.value = '';
  }
});

watch(hubjectParentCpoId, (newVal) => {
  if (newVal && isSameEmpParent.value) {
    hubjectPrefix.value = '';
  }
});

watch(hubjectEnabled, (newVal) => {
  if (!newVal) {
    hubjectPrefix.value = '';
    hubjectParentCpoId.value = '';
    hubjectHotline.value = '';
  }
});

const isHotlineError = computed(() => {
  return isValidating.value && hubjectEnabled.value && hubjectPrefix.value && !hubjectHotline.value;
});

const isHubjectMissingError = computed(() => {
  return isValidating.value && hubjectEnabled.value && !hubjectPrefix.value && !hubjectParentCpoId.value;
});

const isSameEmpParent = computed(() => {
  return !!hubjectParentCpoId.value && parentHash[hubjectParentCpoId.value]?.hubjet_emp_id === props.empId;
});

const componentError = computed(() => {
  return (
    (hubjectEnabled.value && hubjectPrefix.value && !hubjectHotline.value) ||
    (hubjectEnabled.value && !hubjectPrefix.value && !hubjectParentCpoId.value)
  );
});
</script>
