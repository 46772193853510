<template>
  <home-component
    :translations="translations"
    :get-evse-list-route="getEvseListRoute"
    @get-evse-coordinates="coordinates = $event"
    @reset-click="reset = !reset"
  ></home-component>
  <div class="col-8 flex-column d-flex">
    <div v-if="admin" style="background-color: black; color: white; white-space: pre-wrap">{{ systemd }}</div>

    <map-component
      :user-emp-id="userEmpId"
      :user-cpo-id="userCpoId"
      :map-key="mapKey"
      :map-id="mapId"
      :can-see-charge-points="canSeeChargePoints"
      :can-see-hubject="canSeeHubject"
      :coordinates="coordinates"
      :center="center"
      :zoom="zoom"
      :reset="reset"
    ></map-component>
  </div>
</template>

<script setup>
import HomeComponent from '@/HomeComponent.vue';
import MapComponent from '@/MapComponent.vue';
import { ref } from 'vue';

defineProps({
  translations: Object,
  getEvseListRoute: String,
  admin: Boolean,
  userEmpId: { type: Number, required: false },
  userCpoId: { type: String, required: false },
  mapKey: String,
  systemd: { type: String, required: false },
  mapId: { type: String, required: false },
  canSeeChargePoints: Boolean,
  canSeeHubject: Boolean,
  center: String,
  zoom: Number,
});

const coordinates = ref(null);
const reset = ref(false);
</script>
