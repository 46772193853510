<template>
  <template v-if="programId">
    <hubject-pricing-modal-component
      :title="modalTitle"
      :refresh="modalRefresh"
      :initial-days="modalDays"
      :initial-repeat="modalRepeat"
      :initial-end-time="modalEndTime"
      :initial-start-time="modalStartTime"
      :initial-end-date="modalEndDate"
      :initial-start-date="modalStartDate"
      :initial-priority="modalPriority"
      :initial-calculation-type="modalCalculationType"
      :initial-id="modalId"
      :show-a-c3="isEmp"
      @save="save"
    ></hubject-pricing-modal-component>
    <div class="row">
      <div class="col-md-6 px-3 border-right">
        <template v-if="programName">
          <h3>
            Price pools for {{ isEmp && hubjectEmpOperatorId !== 0 ? 'sub-operator' : 'program' }}: {{ programName }}
          </h3>
          <hr />
        </template>
        <div
          v-for="pricingDetail in pricingDetails"
          style="border-width: 1px"
          :class="{ card: true, 'border-primary': pricingDetail.id === pricingDetailsId }"
          @click="$emit('pricingDetailsChange', pricingDetail.id)"
        >
          <div class="card-body" v-if="pricingDetail.start_date">
            <div class="row">
              <div class="col-md-6">
                <dt>Start Date</dt>
                <dd>{{ pricingDetail.start_date }}</dd>
              </div>
              <div class="col-md-6">
                <dt>End Date</dt>
                <dd>{{ pricingDetail.end_date }}</dd>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <dt>Start Time</dt>
                <dd>{{ pricingDetail.start_time }}</dd>
              </div>
              <div class="col-md-6">
                <dt>End Time</dt>
                <dd>{{ pricingDetail.end_time }}</dd>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <dt>Repeat</dt>
                <dd>{{ pricingDetail.repeat ? 'Yes' : 'No' }}</dd>
              </div>
              <div class="col-md-6">
                <dt>Priority</dt>
                <dd>{{ pricingDetail.priority }}</dd>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <dt>Price Calculation Type</dt>
                <dd>{{ pricingDetail.calculation_type.toUpperCase() }}</dd>
              </div>
            </div>
            <div class="row" v-if="pricingDetail.calculation_type === 'daily'">
              <div class="col-md-12">
                <dt>Days</dt>
                <dd>{{ pricingDetail.days.split(',').join(', ') }}</dd>
              </div>
            </div>
            <div class="row" v-if="!Object.keys(pricingDetail.hubject_pricing_power_details).length">
              <div class="col-md-12">
                <hr />
                <h6 class="text-danger font-weight-bold">Power details not created. Price is not assignable!</h6>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="row">
              <div class="col-md-12">
                <dt>Default pricing</dt>
              </div>
            </div>
          </div>
          <div class="card-footer text-right" v-if="pricingDetail.start_date">
            <button
              class="btn btn-sm btn-primary mr-2"
              @click="
                editPricingDetail(
                  pricingDetail.id,
                  pricingDetail.start_date,
                  pricingDetail.end_date,
                  pricingDetail.start_time,
                  pricingDetail.end_time,
                  pricingDetail.priority,
                  pricingDetail.repeat,
                  pricingDetail.days,
                  pricingDetail.calculation_type,
                )
              "
            >
              Edit
            </button>
            <button class="btn btn-sm btn-danger" @click="deletePricingDetail(pricingDetail.id)">Delete</button>
          </div>
        </div>
        <hr />
        <div class="text-right">
          <button class="btn btn-sm btn-primary" @click="addPricingDetail()">Add New Pricing</button>
        </div>
      </div>
      <div class="col-md-6 px-3">
        <hubject-pricing-power-details-component
          :pricing-details-id="pricingDetailsId"
          :power-details="powerDetails"
          :power-details-id="powerDetailsId"
          :show-a-c3="isEmp"
          @savePowerDetail="savePowerDetail"
          @deletePowerDetail="deletePowerDetail"
        ></hubject-pricing-power-details-component>
      </div>
    </div>
  </template>
</template>
<script>
export default {
  emits: ['savePowerDetail', 'savePricingDetail', 'pricingDetailsChange', 'deletePowerDetail', 'deletePricingDetail'],
  props: {
    pricingDetails: {
      type: Object,
      required: true,
    },
    programId: {
      type: Number,
      required: false,
    },
    pricingDetailsId: {
      type: Number,
      required: true,
    },
    programName: {
      type: String,
      required: false,
    },
    isEmp: {
      type: Boolean,
      required: false,
      default: false,
    },
    powerDetailsId: {
      type: Number,
      required: true,
    },
    hubjectEmpOperatorId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    powerDetails() {
      return this.pricingDetailsId ? this.pricingDetails[this.pricingDetailsId].hubject_pricing_power_details : {};
    },
  },
  data: function () {
    return {
      modalStartDate: '',
      modalEndDate: '',
      modalStartTime: '',
      modalEndTime: '',
      modalPriority: 0,
      modalCalculationType: 'daily',
      modalRepeat: false,
      modalId: -1,
      modalDays: '',
      modalRefresh: false,
      modalTitle: '',
    };
  },
  methods: {
    savePowerDetail(id, power, current, pricingType, price, initialFee, freeStart, parkMinute) {
      this.$emit(
        'savePowerDetail',
        id,
        power,
        current,
        pricingType,
        price,
        initialFee,
        freeStart,
        parkMinute,
        this.pricingDetailsId,
      );
    },
    save(
      startDate,
      endDate,
      startTime,
      endTime,
      priority,
      repeat,
      days,
      calculationType,
      id,
      acPerMinute,
      ac3PerMinute,
      dcPerMinute,
      acPerkWh,
      ac3PerkWh,
      dcPerkWh,
    ) {
      this.$emit(
        'savePricingDetail',
        startDate,
        endDate,
        startTime,
        endTime,
        priority,
        repeat,
        days,
        calculationType,
        id,
        acPerMinute,
        ac3PerMinute,
        dcPerMinute,
        acPerkWh,
        ac3PerkWh,
        dcPerkWh,
      );
    },
    deletePowerDetail(id) {
      this.$emit('deletePowerDetail', id, this.pricingDetailsId);
    },
    addPricingDetail() {
      this.modalId = -1;
      this.modalStartDate = '';
      this.modalEndDate = '';
      this.modalStartTime = '';
      this.modalEndTime = '';
      this.modalCalculationType = 'daily';
      this.modalPriority = 0;
      this.modalRepeat = false;
      this.modalDays = 'MON,TUE,WED,THU,FRI,SAT,SUN';
      this.modalTitle = 'Add Pricing Detail';
      this.modalRefresh = !this.modalRefresh;
      $('#pricingDetailsModal').modal('show');
    },
    editPricingDetail(id, startDate, endDate, startTime, endTime, priority, repeat, days, calculationType) {
      this.modalId = id;
      this.modalStartDate = startDate;
      this.modalEndDate = endDate;
      this.modalStartTime = startTime;
      this.modalEndTime = endTime;
      this.modalPriority = priority;
      this.modalRepeat = repeat;
      this.modalDays = days;
      this.modalCalculationType = calculationType;
      this.modalTitle = 'Edit Pricing Detail';
      this.modalRefresh = !this.modalRefresh;
      $('#pricingDetailsModal').modal('show');
    },
    deletePricingDetail(id) {
      if (!confirm('Are you sure you want to delete this pricing detail?')) {
        return;
      }

      this.$emit('deletePricingDetail', id);
    },
  },
};
</script>
