<template>
  <div class="form-group">
    <label class="teal" v-if="label" :for="formName">{{ label }}<slot name="label"></slot></label>
    <input
      :disabled="disabled"
      ref="input_elem"
      :id="formName"
      :name="formName"
      v-bind="$attrs"
      :type="type ?? 'text'"
      @input="input"
      :class="{ 'border-danger': !isValid, 'form-control': true }"
    />
    <span
      class="invalid-feedback"
      style="display: block"
      v-if="!isValid"
      v-text="!requiredValidity ? 'Please provide ' + parsedLabel : 'Please provide a valid ' + parsedLabel"
    ></span>
  </div>
</template>

<script>
export default {
  props: ['label', 'formName', 'value', 'disabled', 'type'],
  inheritAttrs: false,
  inject: {
    isValidating: {
      default: false,
    },
  },
  watch: {
    value: function (newValue) {
      if (newValue === this.$refs.input_elem.value) return;
      this.$refs.input_elem.value = newValue;
      this.setValidity();
    },
  },
  data() {
    return {
      inputValidity: false,
      requiredValidity: false,
      observer: null,
    };
  },
  computed: {
    parsedLabel: function () {
      return this.label.trim().substring(this.label.trim().length - 1) === '*'
        ? this.label
            .trim()
            .substring(0, this.label.length - 1)
            .trim()
        : this.label.trim();
    },
    isValid: function () {
      return this.isValidating && this.isMounted ? this.inputValidity : true;
    },
  },
  methods: {
    setValidity() {
      this.inputValidity = this.$refs.input_elem.checkValidity();
      this.requiredValidity = !this.$refs.input_elem.validity.valueMissing;
    },
    input(event) {
      this.$emit('cg-input', event.target.value);
      this.setValidity();
    },
    startObserving() {
      // Options for the observer (which mutations to observe)
      const config = { attributes: true };

      // Callback function to execute when mutations are observed
      const callback = (mutationList) => {
        for (const mutation of mutationList) {
          this.setValidity();
        }
      };

      // Create an observer instance linked to the callback function
      this.observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      this.observer.observe(this.$refs.input_elem, config);
    },
    stopObserving() {
      this.observer.disconnect();
    },
  },
  mounted() {
    this.isMounted = true;
    this.$refs.input_elem.value = this.value ?? '';
    this.setValidity();
    this.startObserving();
  },
  unmounted() {
    this.stopObserving();
  },
};
</script>
