<template>
  <div class="col-4">
    <div class="card p-3">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th>{{ translations.transaction_count_unfinished }}</th>
            <td>{{ transactions }}</td>
          </tr>
          <tr>
            <th>{{ translations.connected_charge_points }}</th>
            <td>{{ connected }}</td>
          </tr>
          <tr>
            <th>{{ translations.disconnected_charge_points }}</th>
            <td>{{ notConnected }}</td>
          </tr>
          <tr>
            <th>{{ translations.last_message_received }}</th>
            <td>{{ lastMessageReceived }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card p-3" v-if="adminConnected != null">
      <div class="card-header">
        <div class="card-title">Total Count For Admins</div>
      </div>
      <div class="card-body">
        <div class="card-text">
          <table class="table table-striped">
            <tbody>
              <tr>
                <th>{{ translations.transaction_count_unfinished }}</th>
                <td>{{ adminTransactions }}</td>
              </tr>
              <tr>
                <th>{{ translations.connected_charge_points }}</th>
                <td>{{ adminConnected }}</td>
              </tr>
              <tr>
                <th>{{ translations.disconnected_charge_points }}</th>
                <td>{{ adminNotConnected }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card p-3">
      <div class="card-header">
        <div class="card-title">Find EVSE</div>
      </div>
      <div class="card-body">
        <div class="card-text">
          <div class="form-group">
            <label for="evse-prefix">Enter Hubject EVSE id:</label>
            <input
              @input="getEvseIds"
              class="form-control"
              list="evse-list"
              id="evse-prefix"
              name="evse-prefix"
              v-model="evseIdModel"
            />
          </div>
          <datalist id="evse-list">
            <option v-for="evseId in evseIds" :value="evseId"></option>
          </datalist>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" @click="$emit('resetClick')">{{ translations.center_map }}</button>
  </div>
</template>
<script>
export default {
  props: ['translations', 'getEvseListRoute'],
  emits: ['getEvseCoordinates', 'resetClick'],
  data: function () {
    return {
      connected: null,
      notConnected: null,
      transactions: null,
      message: null,
      adminConnected: null,
      adminNotConnected: null,
      adminTransactions: null,
      adminMessage: null,
      lastMessageReceived: null,
      evseIds: [],
      evseObject: {},
      evseIdModel: null,
    };
  },
  methods: {
    getData: function () {
      axios
        .get('/get-data')
        .then((response) => {
          this.connected = response.data.connected;
          this.notConnected = response.data.notConnected;
          this.transactions = response.data.transactions;
          this.message = response.data.message;
          this.adminConnected = response.data.adminConnected;
          this.adminNotConnected = response.data.adminNotConnected;
          this.adminTransactions = response.data.adminTransactions;
          this.adminMessage = response.data.adminMessage;
          this.lastMessageReceived = response.data.lastMessageReceived;
          this.error = '';
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.error = error.response.data.error;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    },
    getEvseIds: function () {
      if (this.evseIdModel.length < 6) {
        this.evseIds = [];
        return;
      }

      if (this.evseIds.length) {
        if (this.evseObject[this.evseIdModel]) {
          this.$emit('getEvseCoordinates', this.evseObject[this.evseIdModel]);
        }

        return;
      }

      axios
        .get(this.getEvseListRoute + '?prefix=' + this.evseIdModel.substring(0, 6))
        .then((response) => {
          this.evseIds = Object.keys(response.data.data);
          this.evseObject = response.data.data;
          if (this.evseObject[this.evseIdModel]) {
            this.$emit('getEvseCoordinates', this.evseObject[this.evseIdModel]);
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            this.error = error.response.data.error;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    },
  },
  mounted() {
    window.setInterval(() => this.getData(), 10000);
    this.getData();
  },
};
</script>
