<template>
  <div class="mb-1 form-inline">
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" id="statusMessage" v-model="statusMessage" />
      <label class="form-check-label" for="statusMessage">Status</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" id="meter" v-model="meter" />
      <label class="form-check-label" for="meter">Meter Values</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" id="transaction" v-model="transaction" />
      <label class="form-check-label" for="transaction">Transactions</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" id="authorisation" v-model="authorisation" />
      <label class="form-check-label" for="authorisation">Authorisation</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" id="other" v-model="other" />
      <label class="form-check-label" for="other">Other</label>
    </div>
    <div class="form-group ml-auto">
      <select v-model="connector" class="form-control">
        <option value="0">All connectors</option>
        <option v-for="conn in connectors" :value="conn">
          {{ 'Connector ' + conn }}
        </option>
      </select>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr class="teal">
          <th scope="col">Action</th>
          <th scope="col">Request</th>
          <th scope="col">Response</th>
          <th scope="col">Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in filteredData">
          <td scope="row" class="align-middle">{{ row.action }}</td>
          <td class="align-middle">
            <pre>{{ JSON.stringify(JSON.parse(row.request_payload), null, 2) }}</pre>
          </td>
          <td class="align-middle">
            <pre>{{ JSON.stringify(JSON.parse(row.response_payload), null, 2) }}</pre>
          </td>
          <td class="align-middle">{{ row.timestamp }}</td>
        </tr>
      </tbody>
    </table>
    <h6 v-if="!data.length && status !== 'loading'" class="text-danger font-weight-bold">No data found</h6>
    <h6 v-else-if="!filteredData.length && status !== 'loading'" class="text-danger font-weight-bold">
      No data found for selected filters
    </h6>
  </div>
</template>
<script>
export default {
  props: ['data', 'connectors', 'status'],
  data: function () {
    return {
      statusMessage: false,
      meter: false,
      transaction: true,
      authorisation: true,
      other: true,
      connector: '0',
    };
  },
  computed: {
    filteredData: function () {
      const actions = [];
      const all = [
        'Authorize',
        'StatusNotification',
        'MeterValues',
        'RemoteStartTransaction',
        'RemoteStopTransaction',
        'StartTransaction',
        'StopTransaction',
      ];

      if (this.statusMessage) {
        actions.push('StatusNotification');
      }

      if (this.meter) {
        actions.push('MeterValues');
      }

      if (this.transaction) {
        actions.push('RemoteStartTransaction', 'RemoteStopTransaction', 'StartTransaction', 'StopTransaction');
      }

      if (this.authorisation) {
        actions.push('Authorize');
      }

      return this.data.filter(
        (x) =>
          (this.connector === '0' || x.connectorId === +this.connector) &&
          (actions.indexOf(x.action) !== -1 || (this.other && all.indexOf(x.action) === -1)),
      );
    },
  },
};
</script>
