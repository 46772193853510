<template>
    <div class="container-fluid">
        <div class="mt-5 card p-3">
            <div class="form-inline mb-3">
                <div class="form-group mr-3" v-if="!superUser">
                    <label for="cpo" class="mr-2">
                        {{ translations.charge_point_operator }}
                    </label>
                    <select id="cpo" v-model="cpo" class="form-control">
                        <option value="">All</option>
                        <option v-for="(name, id) in cpoList" :value="id">{{ name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="search" class="mr-2">
                        {{ translations.search }}
                    </label>
                    <input id="search" v-model="search" class="form-control" type="text" />
                </div>
                <div class="ml-auto">
                    <a :href="addRoleRoute" class="btn btn-primary">Create new role</a>
                </div>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr class="teal">
                        <th scope="col">#</th>
                        <th scope="col">{{ translations.role_name }}</th>
                        <th scope="col" v-if="!superUser">{{ translations.charge_point_operator }}</th>
                        <th scope="col">{{ translations.role_type }}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(role, index) in finalList">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ role.local_role_name }}</td>
                        <td v-if="!superUser">{{ cpoList[role.charge_point_operator_id] }}</td>
                        <td>{{ role.role_type }}</td>
                        <td v-if="role.local_role_name !== 'admin' && role.local_role_name !== 'super-admin'">
                            <a :href="'/admin/role/permissions/' + role.id" class="btn btn-sm btn-success mr-2">
                                Permissions
                            </a>
                            <a
                                v-if="canManage"
                                :href="'/admin/role/edit/' + role.id"
                                class="btn btn-sm btn-primary mr-2"
                                >Edit</a
                            >
                            <a v-if="canManage" @click="deleteRole(role.id)" class="btn btn-sm btn-danger">Delete</a>
                        </td>
                        <td v-else>System role</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "RolesIndexComponent",
    props: ["cpoList", "rolesList", "superUser", "translations", "addRoleRoute", "canManage"],
    data: function () {
        return {
            cpo: "",
            search: "",
        };
    },
    computed: {
        finalList() {
            if (!this.cpo && !this.search) {
                return this.rolesList;
            }

            return this.rolesList.filter(
                (x) =>
                    ((this.cpo && x.charge_point_operator_id === +this.cpo) || !this.cpo) &&
                    ((this.search && x.local_role_name.toLowerCase().includes(this.search.toLowerCase())) ||
                        !this.search)
            );
        },
    },
    methods: {
        deleteRole(id) {
            if (confirm("Are you sure you want to delete this role?")) {
                axios
                    .delete("/admin/role/delete/" + id)
                    .then((response) => {
                        if (response.data.success) {
                            alert("Role deleted successfully");
                            window.location.reload();
                        } else {
                            alert("Something went wrong");
                        }
                    })
                    .catch((error) => {
                        if (error && error.response && error.response.data) {
                            alert(error.response.data.message);
                        } else {
                            alert("Something went wrong");
                            console.log(error);
                        }
                    });
            }
        },
    },
};
</script>
