<template>
  <cg-input
    :label="labelBalance"
    form-name="amount"
    @cg-input="changeBalance"
    type="number"
    step="0.01"
    :max="paymentMaxValue"
    required
  ></cg-input>

  <cg-input :label="labelDescription" form-name="description" type="text" required></cg-input>
  <cg-check
    :disabled="paymentDisabled"
    :label="labelPayment"
    form-name="payment"
    :info="paymentInfo"
    @cg-input="changePayment"
  ></cg-check>
  <cg-check :disabled="fiscalDisabled" :label="labelFiscal" form-name="fiscal" :info="fiscalInfo"></cg-check>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps([
  'labelBalance',
  'labelDescription',
  'labelPayment',
  'labelFiscal',
  'threshold',
  'hasToken',
  'underThreshold',
  'topUpAmount',
]);

const fiscalInfoMessage = '<em>Fiscal Advance Invoice can only be issued for positive balance change</em>';
const paymentInfoMessageDisabled =
  '<em>Charging credit card can only be made when the balance is less than balance for automatic payment, ' +
  'customer has valid payment token and balance change is positive</em>';

const paymentInfoMessageEnabled =
  `<em>Max value that can be charged is auto top up value for customer: ${props.topUpAmount.toFixed(2)}</em><br />` +
  '<strong class="text-danger">Use with extreme caution!!!</strong>';

const paymentDisabled = ref(true);
const fiscalDisabled = ref(true);
const fiscalInfo = ref(fiscalInfoMessage);
const paymentInfo = ref(paymentInfoMessageDisabled);
const paymentMaxValue = ref(null);

function changeBalance(value) {
  paymentDisabled.value = isPaymentDisabled(value);
  paymentInfo.value = isPaymentDisabled(value) ? paymentInfoMessageDisabled : paymentInfoMessageEnabled;
  fiscalDisabled.value = value <= 0;
  fiscalInfo.value = value <= 0 ? fiscalInfoMessage : '';
}

function changePayment(value) {
  paymentMaxValue.value = value ? props.topUpAmount : null;
}

function isPaymentDisabled(value) {
  return !props.hasToken || !props.underThreshold || value <= 0;
}
</script>
