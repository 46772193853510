/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import { createApp, ref } from 'vue';

import BalanceComponent from './components/customer/BalanceComponent.vue';
import FiscalListComponent from './components/customer/FiscalListComponent.vue';
import DashboardComponent from './components/dashboard/DashboardComponent.vue';
import StatusesComponent from './components/StatusesComponent.vue';
import NavigationReportComponent from './components/reports/NavigationReportComponent.vue';
import HubjectOperatorListComponent from './components/HubjectOperatorListComponent.vue';
import RolesIndexComponent from './components/roles/RolesIndexComponent.vue';
import UsersIndexComponent from './components/users/UsersIndexComponent.vue';

import PowerModalComponent from './components/hubject/pricing/PowerModalComponent.vue';
import PowerDetailsComponent from './components/hubject/pricing/PowerDetailsComponent.vue';
import PricingDetailsComponent from './components/hubject/pricing/PricingDetailsComponent.vue';
import PricingModalComponent from './components/hubject/pricing/PricingModalComponent.vue';
import ProgramComponent from './components/hubject/pricing/ProgramComponent.vue';
import ProgramModalComponent from './components/hubject/pricing/ProgramModalComponent.vue';
import HubjectEmpPricingComponent from './components/hubject/pricing/HubjectEmpPricingComponent.vue';
import PricingAssignmentLayout from './components/hubject/pricing_assignments/AssignmentLayout.vue';
import ImagesComponent from './components/ImagesComponent.vue';
import DirectionsComponent from './components/DirectionsComponent.vue';
import InputComponent from './components/forms/InputComponent.vue';
import SelectComponent from './components/forms/SelectComponent.vue';
import CheckComponent from './components/forms/CheckComponent.vue';
import HubjectComponent from './components/forms/cpo/HubjectComponent.vue';
import FormComponent from './components/forms/FormComponent.vue';
import IndexComponent from './components/IndexComponent.vue';
import ChargePointSelectComponent from './components/forms/ChargePointSelectComponent.vue';

/**
 * Next, we will create a fresh Vue application instance. You may then begin
 * registering components with the application instance so they are ready
 * to use in your application's views. An example is included for you.
 */
const app = createApp({});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

app.component('v-select', vSelect);
app.component('dashboard-component', DashboardComponent);
app.component('statuses-component', StatusesComponent);
app.component('navigation-report-component', NavigationReportComponent);
app.component('index-component', IndexComponent);
app.component('hubject-operator-list-component', HubjectOperatorListComponent);
app.component('roles-index-component', RolesIndexComponent);
app.component('users-index-component', UsersIndexComponent);

app.component('hubject-pricing-power-modal-component', PowerModalComponent);
app.component('hubject-pricing-power-details-component', PowerDetailsComponent);
app.component('hubject-pricing-details-component', PricingDetailsComponent);
app.component('hubject-pricing-modal-component', PricingModalComponent);
app.component('hubject-pricing-program-component', ProgramComponent);
app.component('hubject-program-model-component', ProgramModalComponent);
app.component('hubject-emp-pricing-component', HubjectEmpPricingComponent);
app.component('hubject-pricing-assignment-layout', PricingAssignmentLayout);
app.component('images-component', ImagesComponent);
app.component('directions-component', DirectionsComponent);
app.component('cg-input', InputComponent);
app.component('cg-select', SelectComponent);
app.component('cg-check', CheckComponent);
app.component('cg-cpo-hubject', HubjectComponent);
app.component('cg-form', FormComponent);
app.component('customer-balance-component', BalanceComponent);
app.component('fiscal-list-component', FiscalListComponent);
app.component('charge-point-select-component', ChargePointSelectComponent);

// Object.entries(import.meta.glob('./**/*.vue', { eager: true })).forEach(([path, definition]) => {
//     app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
// });

/**
 * Finally, we will attach the application instance to a HTML element with
 * an "id" attribute of "app". This element is included with the "auth"
 * scaffolding. Otherwise, you will need to add an element yourself.
 */

app.config.globalProperties.$selectOption = ref('');
window.vue_root_elem = app.mount('#app');
window.vue_app = app;
