<template>
    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <dt>Level</dt>
                <dd v-if="item.charge_point_id">Charge Point</dd>
                <dd v-else-if="item.location_id">Location</dd>
                <dd v-else-if="item.charge_point_operator_id">Charge Point Operator</dd>
                <dd v-else>Energy Mobility Provider</dd>
                <dt>Program</dt>
                <dd>{{ item.hubject_pricing_program.name }}</dd>
                <dt>Priority</dt>
                <dd>{{ item.calculated_priority }}</dd>
            </div>
        </div>
        <div class="row" v-if="remove">
            <div class="col-12 text-right">
                <div class="btn btn-danger" @click="removeAssignment">Remove</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssignmentPrograms',
    props: {
        item: {},
        remove: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        removeAssignment() {
            this.$emit('removeAssignment', this.item);
        },
    },
};
</script>
