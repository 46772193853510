<template>
  <div class="form-group">
    <div class="d-flex custom-control custom-checkbox" style="padding-left: 0.5rem">
      <input
        type="checkbox"
        :name="formName"
        :id="formName"
        class="my-0 custom-control-input"
        :checked="inputValue"
        :disabled="disabled"
        @change="$emit('cg-input', $event.target.checked)"
      />
      <label class="teal my-0 ml-3 custom-control-label" :for="formName">{{ label }}</label>
    </div>
    <span v-if="info" class="text-primary" v-html="info"></span>
  </div>
</template>

<script>
export default {
  props: ['label', 'formName', 'value', 'disabled', 'info'],
  inheritAttrs: false,
  data() {
    return {
      inputValue: (this.value ?? false) === '0' ? false : !!(this.value ?? false),
    };
  },
};
</script>
