<template>
  <div class="mb-1 form-inline">
    <div class="form-group ml-auto">
      <select v-model="connector" class="form-control">
        <option value="0">All connectors</option>
        <option v-for="conn in connectors" :value="conn">
          {{ 'Connector ' + conn }}
        </option>
      </select>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr class="teal">
          <th scope="col">Connector ID</th>
          <th scope="col">Error Code</th>
          <th scope="col">Info</th>
          <th scope="col">Status</th>
          <th scope="col">Timestamp</th>
          <th scope="col">Vendor ID</th>
          <th scope="col">Vendor Error Code</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in filteredData">
          <td class="align-middle">{{ row.connector_id }}</td>
          <td class="align-middle">{{ row.error_code }}</td>
          <td class="align-middle">{{ row.info }}</td>
          <td class="align-middle">{{ row.status }}</td>
          <td class="align-middle">{{ row.timestamp }}</td>
          <td class="align-middle">{{ row.vendor_id }}</td>
          <td class="align-middle">{{ row.vendor_error_code }}</td>
        </tr>
      </tbody>
    </table>
    <h6 v-if="!data.length && status !== 'loading'" class="text-danger font-weight-bold">No data found</h6>
    <h6 v-else-if="!filteredData.length && status !== 'loading'" class="text-danger font-weight-bold">
      No data found for selected filters
    </h6>
  </div>
</template>
<script>
export default {
  props: ['data', 'connectors', 'status'],
  data: function () {
    return {
      connector: '0',
    };
  },
  computed: {
    filteredData: function () {
      return this.data.filter((x) => this.connector === '0' || x.connector_id === +this.connector);
    },
  },
};
</script>
