<template>
  <div
    class="modal fade"
    id="programDetailsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form ref="power_form" @submit.prevent="save">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-borderless">
              <tbody>
                <tr v-if="(initialId === -1 && !chargePointRestrictedId) || showAC3">
                  <th class="align-middle">{{ showAC3 ? 'Hubject Evse Prefix' : 'Charge Point Operator' }}</th>
                  <td>
                    <select v-model="cpoId" class="form-control" required>
                      <option value="">Please select</option>
                      <option :value="showAC3 ? 'default' : -1">
                        {{ showAC3 ? 'Default Pricing' : 'Top Level EMP Program' }}
                      </option>
                      <option v-for="(value, key) in chargePointOperators" :value="key">
                        {{ value }}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr v-if="!showAC3 || (hubjectEmpOperatorId !== 0 && cpoId !== 'default' && cpoId)">
                  <th class="align-middle">{{ showAC3 ? 'Additional Prefix Data' : 'Name' }}</th>
                  <td>
                    <input type="text" :required="!showAC3" v-model="name" class="form-control" />
                  </td>
                </tr>
              </tbody>
            </table>
            <template v-if="initialId === -1">
              <hr />
              <table class="table-striped table table-bordered">
                <tr>
                  <th></th>
                  <th class="text-center">AC</th>
                  <th v-if="showAC3" class="text-center">AC-3</th>
                  <th class="text-center">DC</th>
                </tr>
                <tr>
                  <th class="align-middle">Per kWh</th>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="acPerkWh" class="form-control" />
                  </td>
                  <td v-if="showAC3">
                    <input type="number" required min="0" step="0.01" v-model="ac3PerkWh" class="form-control" />
                  </td>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="dcPerkWh" class="form-control" />
                  </td>
                </tr>
                <tr>
                  <th class="align-middle">Per minute</th>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="acPerMinute" class="form-control" />
                  </td>
                  <td v-if="showAC3">
                    <input type="number" required min="0" step="0.01" v-model="ac3PerMinute" class="form-control" />
                  </td>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="dcPerMinute" class="form-control" />
                  </td>
                </tr>
              </table>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <input type="submit" class="btn btn-primary" value="Save" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['save'],
  props: {
    initialName: {
      type: String,
      required: false,
    },
    initialCpoId: {
      type: [String, Number],
      required: false,
    },
    initialId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: true,
    },
    chargePointOperators: {
      type: Object,
      required: true,
    },
    chargePointRestrictedId: {
      type: Number,
      required: false,
    },
    showAC3: {
      type: Boolean,
      required: false,
      default: false,
    },
    hubjectEmpOperatorId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      name: this.initialName,
      cpoId: this.initialCpoId,
      acPerMinute: 0,
      ac3PerMinute: 0,
      dcPerMinute: 0,
      acPerkWh: 0,
      ac3PerkWh: 0,
      dcPerkWh: 0,
    };
  },
  watch: {
    refresh: function () {
      this.name = this.showAC3 ? this.initialCpoId.substring(6) : this.initialName;
      this.cpoId = this.showAC3 ? this.initialCpoId.substring(0, 6) : this.initialCpoId;
      this.acPerMinute = 0;
      this.ac3PerMinute = 0;
      this.dcPerMinute = 0;
      this.acPerkWh = 0;
      this.ac3PerkWh = 0;
      this.dcPerkWh = 0;
    },
  },
  methods: {
    save() {
      if (!this.$refs.power_form.checkValidity()) {
        return;
      }

      if (this.initialId === -1) {
        if (
          (this.acPerkWh || this.ac3PerkWh || this.dcPerkWh) &&
          (this.acPerMinute || this.ac3PerMinute || this.dcPerMinute)
        ) {
          alert('Please enter either per kWh or per minute price, not both.');
          return;
        }

        if (
          (!this.acPerkWh && !this.acPerMinute) ||
          (!this.dcPerkWh && !this.dcPerMinute) ||
          (!this.ac3PerkWh && !this.ac3PerMinute && this.showAC3)
        ) {
          alert('Please enter at least one price type for all positions.');
          return;
        }
      }

      this.$emit(
        'save',
        this.name,
        this.cpoId === -1 ? null : this.showAC3 ? this.cpoId + this.name : this.cpoId,
        this.initialId,
        this.chargePointRestrictedId,
        this.acPerMinute,
        this.ac3PerMinute,
        this.dcPerMinute,
        this.acPerkWh,
        this.ac3PerkWh,
        this.dcPerkWh,
      );
    },
    close() {
      $('#programDetailsModal').modal('hide');
    },
  },
};
</script>
