<template>
  <table id="cp" class="table table-striped table-bordered">
    <thead>
      <tr>
        <th>Connection</th>
        <th>Status</th>
        <th>Manual Status</th>
        <th>Error</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-white" :style="{ backgroundColor: connection ? 'green' : 'red' }">
          {{ connection ? 'Connected' : 'Disconnected' }}
        </td>
        <td
          class="text-white"
          :style="{
            backgroundColor: status === 'Available' ? 'green' : 'red',
          }"
        >
          {{ status }}
        </td>
        <td class="text-white" :style="{ backgroundColor: manualStatus ? 'red' : 'green' }">
          {{ manualStatus ? 'Off' : 'On' }}
        </td>
        <td
          class="text-white"
          :style="{
            backgroundColor: error !== 'NoError' ? 'red' : 'green',
          }"
        >
          {{ error }}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex">
    <button v-if="canControl" :disabled="disabledButton" @click="hardReset" class="btn btn-danger mr-2">
      Hard Reset
    </button>
    <button v-if="canControl" :disabled="disabledButton" @click="softReset" class="btn btn-danger mr-2">
      Soft Reset
    </button>
    <button
      v-if="canControl"
      :disabled="disabledButton"
      @click="setManual"
      :class="['mr-2', 'btn', manualStatus ? 'btn-success' : 'btn-danger']"
    >
      {{ manualStatus ? 'Turn on Charge Point' : 'Turn off Charge Point' }}
    </button>
    <button v-if="canControl" :disabled="disabledButton" @click="getConfiguration" class="btn btn-secondary mr-2">
      Get Configuration
    </button>
    <!--    <button v-if="canControl" disabled="disabledButton" @click="hardReset">Get Diagnostics</button>-->
    <button v-if="canControl" :disabled="disabledButton" @click="pushServerData" class="btn btn-secondary mr-2">
      Getting Server Status
    </button>
    <a class="ml-auto btn btn-primary mr-2" :href="reportsRoute">Reports</a>
    <a class="btn btn-primary mr-2" :href="listRoute">Charge Points List</a>
  </div>

  <hr />
  <h3>Pools</h3>
  <template v-for="pool in pools">
    <table class="table table-striped table-bordered" style="table-layout: fixed">
      <thead>
        <tr>
          <th style="width: 100px">Id</th>
          <th>Name</th>
          <th>Power (kW)</th>
          <th>Manual Status</th>
          <th>Final Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ pool.pool.id }}</td>
          <td>{{ pool.pool.name }}</td>
          <td>{{ pool.pool.max_power }}</td>
          <td>{{ pool.pool.manual_status }}</td>
          <td class="text-white" :style="{ backgroundColor: getColor(pool.pool.final_status, 'pool') }">
            {{ pool.pool.final_status }}
          </td>
          <td>
            <button
              v-if="canControl"
              @click="setManualPool(pool.pool.id, pool.pool.manual_status === 'available' ? 0 : 1)"
              :disabled="disabledButton"
              class="btn btn-primary"
            >
              {{ pool.pool.manual_status === 'available' ? 'Turn off pool' : 'Turn on pool' }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="margin: 10px">
      <table class="table table-striped table-bordered" style="table-layout: fixed">
        <thead>
          <tr>
            <th style="width: 150px">Connector Id</th>
            <th style="width: 150px">Connector No</th>
            <th>Status</th>
            <th>Availability</th>
            <th>Error</th>
            <th>In Transaction</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="connector in pool.connectors">
            <td>
              {{ connector.id }}
            </td>
            <td>
              {{ connector.connector_id }}
            </td>
            <td class="text-white" :style="{ backgroundColor: getColor(connector.final_status) }">
              {{ connector.status }}
            </td>
            <td>
              {{ connector.availability }}
            </td>
            <td
              class="text-white"
              :style="{ backgroundColor: connector.error_code !== 'NoError' ? '#FF3300' : '#33CC00' }"
            >
              {{ connector.error_code }}
            </td>
            <td :style="{ backgroundColor: transactions[connector.connector_id] ? 'orange' : 'inherit' }">
              {{ transactions[connector.connector_id] ? 'Yes' : 'No' }}
            </td>
            <td>
              <button
                v-if="
                  transactions[connector.connector_id] &&
                  !transactions[connector.connector_id]['backend_stop'] &&
                  canControl
                "
                @click="stopTransaction(connector.connector_id)"
                :disabled="callInProgress || !connection"
                class="btn btn-primary"
              >
                Stop Transaction
              </button>
              <button
                v-if="
                  transactions[connector.connector_id] &&
                  (transactions[connector.connector_id]['backend_stop'] || !connection) &&
                  canControl
                "
                @click="stopBilling(connector.connector_id)"
                :disabled="callInProgress"
                class="btn btn-primary"
              >
                Stop Billing
              </button>
              <button
                v-if="!transactions[connector.connector_id] && canControl"
                @click="unlockConnector(connector.connector_id)"
                :disabled="disabledButton"
                class="btn btn-primary"
              >
                Unlock Connector
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <hr />
  <ConfigurationComponent
    v-if="configDisplayed"
    :configData="configData"
    @saveConfig="save"
    :callInProgress="callInProgress"
    :resultData="resultData"
  />

  <button v-if="configDisplayed && canControl" @click="close" class="btn btn-secondary">Close</button>
</template>

<style scoped>
td {
  vertical-align: middle !important;
  width: 100px;
}
</style>

<script>
import ConfigurationComponent from './ConfigurationComponent.vue';

export default {
  components: { ConfigurationComponent },
  props: [
    'statusRoute',
    'commandRoute',
    'manualRoute',
    'manualPoolRoute',
    'getConfigurationRoute',
    'changeConfigurationRoute',
    'token',
    'userId',
    'websocketBaseUrl',
    'chargePointId',
    'listRoute',
    'reportsRoute',
    'canControl',
  ],
  data() {
    return {
      connection: false,
      manualStatus: false,
      status: '',
      error: '',
      pools: [],
      transactions: [],
      callInProgress: false,
      configData: [],
      resultData: {},
      configDisplayed: false,
      chargePoint: null,
    };
  },
  methods: {
    hardReset: function () {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.buttonClick();
      this.sendCommand('HardReset');
    },
    softReset: function () {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.buttonClick();
      this.sendCommand('SoftReset');
    },
    pushServerData: function () {
      this.buttonClick();
      this.sendCommand('PushServerData');
    },
    stopTransaction: function (connectorId) {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.buttonClick();
      this.sendCommand('StopTransaction', connectorId, true);
    },
    stopBilling: function (connectorId) {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.buttonClick();
      this.sendCommand('StopBilling', connectorId, true);
    },
    unlockConnector: function (connectorId) {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.buttonClick();
      this.sendCommand('UnlockConnector', connectorId);
    },
    buttonClick: function () {
      this.callInProgress = true;
      this.configDisplayed = false;
    },
    ajaxCall: function (
      url,
      method = 'put',
      data = { charge_point_id: this.chargePointId },
      processFinally = false,
      callback = null,
    ) {
      const axiosCall = axios[method](url, data)
        .then(function (response) {
          if (callback) {
            callback();
          }
          console.log('apiResponse', response);
        })
        .catch(function (error) {
          alert(error);
        });

      if (processFinally) {
        axiosCall.finally(() => {
          this.callInProgress = false;
          this.statusCall();
        });
      }
    },
    sendCommand: function (command, data = null, enableCommands = false) {
      this.ajaxCall(
        this.commandRoute,
        'put',
        {
          charge_point_id: this.chargePointId,
          command,
          data,
        },
        enableCommands,
      );
    },
    getConfiguration: function () {
      this.buttonClick();
      this.ajaxCall(this.getConfigurationRoute, 'post');
    },
    changeConfiguration: function (configData) {
      this.resultData[configData.key] = { status: 'in progress' };
      this.callInProgress = true;
      this.ajaxCall(this.changeConfigurationRoute, 'put', {
        charge_point_id: this.chargePointId,
        config_data: configData,
      });
    },
    setManual: function () {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.buttonClick();
      this.ajaxCall(
        this.manualRoute,
        'post',
        {
          charge_point_id: this.chargePointId,
          manual_status: this.manualStatus ? 0 : 1,
        },
        true,
        () => {
          this.manualStatus = !this.manualStatus;
        },
      );
    },
    setManualPool: function (poolId, status) {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.buttonClick();
      this.ajaxCall(
        this.manualPoolRoute,
        'post',
        {
          pool_id: poolId,
          manual_status: status,
        },
        true,
        () => {},
      );
    },
    statusCall: function () {
      axios
        .get(this.statusRoute)
        .then((response) => {
          const cp = response.data.charge_point;
          this.connection = cp.is_connected;
          this.manualStatus = cp.is_manual_off;
          this.status = cp.status;
          this.error = cp.error_code;
          this.transactions = response.data.transactions;
          this.pools = response.data.pools;
          this.chargePoint = cp;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close: function () {
      this.configData = [];
      this.resultData = [];
      this.configDisplayed = false;
    },
    save: function (configData) {
      this.callInProgress = true;
      this.changeConfiguration(configData);
    },
    processWebsocket: function () {
      const ws = new WebSocket(this.websocketBaseUrl + '-2/' + this.token + '/' + this.token + '/' + this.userId);
      ws.onopen = function (event) {
        console.log(event);
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'GetConfiguration') {
          this.configData = message.data.keys;
          this.configDisplayed = true;
          this.callInProgress = false;
        } else if (message.type === 'ChangeConfiguration') {
          this.resultData[message.data.configurationKey] = {
            status: message.data.status,
            value: message.data.configurationValue,
          };
          this.callInProgress = false;
        } else if (message.type === 'PushServerData') {
          console.table(message.data);
          this.callInProgress = false;
        } else if (message.type === 'HardReset' || message.type === 'SoftReset' || message.type === 'UnlockConnector') {
          this.callInProgress = false;
        }

        console.log('websocket', message);
      };

      ws.onclose = function () {
        // if connection breaks, restart in 5 seconds
        window.setTimeout(this.processWebsocket, 5000);
      };
    },
    getColor: function (status, type) {
      if (!this.connection) {
        return '#ccc';
      }

      switch (status) {
        case 'available':
          return type === 'pool' ? 'green' : '#33CC00';
        case 'reserved':
        case 'occupied':
          return 'orange';
        default:
          return type === 'pool' ? 'red' : '#FF3300';
      }
    },
  },
  computed: {
    transactionInProgress: function () {
      return Object.values(this.transactions).some((x) => x && x.finished === false);
    },
    disabledButton: function () {
      return this.transactionInProgress || this.callInProgress || !this.connection;
    },
  },
  mounted() {
    console.log('Component mounted.');
    window.setInterval(this.statusCall, 5000);
    this.statusCall();
    this.processWebsocket();
  },
};
</script>
