<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr class="teal">
          <th scope="col">Action</th>
          <th scope="col">Request</th>
          <th scope="col">Response</th>
          <th scope="col">Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in data">
          <td class="align-middle">{{ row.action }}</td>
          <td class="align-middle">
            <pre>{{ JSON.stringify(JSON.parse(row.request_payload), null, 2) }}</pre>
          </td>
          <td class="align-middle">
            <pre>{{ JSON.stringify(JSON.parse(row.response_payload), null, 2) }}</pre>
          </td>
          <td class="align-middle">{{ row.created_at_string }}</td>
        </tr>
      </tbody>
    </table>
    <h6 v-if="!data.length && status !== 'loading'" class="text-danger font-weight-bold">No data found</h6>
  </div>
</template>
<script>
export default {
  props: ['data', 'status'],
};
</script>
