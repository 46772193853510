<template>
  <div class="card my-3 p-3">
    <form onsubmit="event.preventDefault()">
      <div class="form-group mr-2">
        <label for="from">From:</label>
        <input type="datetime-local" class="form-control" id="from" name="start_time" v-model="dataStartTime" />
      </div>
      <div class="form-group mr-2">
        <label for="to">To:</label>
        <input type="datetime-local" class="form-control" id="to" name="end_time" v-model="dataEndTime" />
      </div>

      <div class="form-group mr-2">
        <label for="timezone">Timezone:</label>
        <select class="form-control" id="timezone" name="timezone" v-model="dataTimezone">
          <option value="utc">UTC</option>
          <option value="local">Local</option>
        </select>
      </div>

      <input type="submit" class="btn btn-primary" :disabled="submitDisabled" @click="sendData" />
    </form>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      dataStartTime: null,
      dataEndTime: null,
      dataTimezone: 'local',
      submitDisabled: false,
    };
  },
  methods: {
    sendData: function (event) {
      this.$emit('submitDate', {
        start_time: this.dataStartTime,
        end_time: this.dataEndTime,
        timezone: this.dataTimezone,
      });
      this.submitDisabled = true;
    },

    enableButton: function () {
      this.submitDisabled = false;
    },
  },
  mounted() {
    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
      return (
        [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
        ' ' +
        [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
      );
    }

    const url = new URLSearchParams(window.location.search);
    if (url.has('start_time')) {
      this.dataStartTime = url.get('start_time');
      this.dataEndTime = url.get('end_time');
      this.dataTimezone = url.get('timezone');
    } else {
      const start = new Date();
      start.setHours(0, 0, 0, 0);
      this.dataStartTime = formatDate(start);

      const end = new Date();
      end.setHours(23, 59, 0, 0);
      this.dataEndTime = formatDate(end);
    }

    this.sendData();
  },
};
</script>
