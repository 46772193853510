<template>
    <div class="container-fluid">
        <div class="mt-5 card p-3">
            <div class="form-inline mb-3">
                <div class="form-group mr-3" v-if="!superUser">
                    <label for="cpo" class="mr-2">
                        {{ translations.charge_point_operator }}
                    </label>
                    <select id="cpo" v-model="cpo" class="form-control">
                        <option value="">All</option>
                        <option v-for="(name, id) in cpoList" :value="id">{{ name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="search" class="mr-2">
                        {{ translations.search }}
                    </label>
                    <input id="search" v-model="search" class="form-control" type="text" />
                </div>
                <div class="ml-auto">
                    <a :href="addUserRoute" class="btn btn-primary">Create new user</a>
                </div>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr class="teal">
                        <th scope="col">#</th>
                        <th scope="col">{{ translations.user_name }}</th>
                        <th scope="col">{{ translations.email }}</th>
                        <th scope="col">{{ translations.email_verified }}</th>
                        <th scope="col" v-if="!superUser">{{ translations.charge_point_operator }}</th>
                        <th scope="col">{{ translations.user_type }}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, index) in finalList">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.email_verified_at ? "Yes" : "No" }}</td>
                        <td v-if="!superUser">{{ cpoList[user.charge_point_operator_id] }}</td>
                        <td>{{ user.user_type }}</td>
                        <td>
                            <div class="dropdown">
                                <button
                                    class="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Options
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a v-if="canManage" :href="'/admin/user/edit/' + user.id" class="dropdown-item">
                                        Edit
                                    </a>
                                    <a
                                        v-if="!user.email_verified_at && canManage"
                                        @click="resendMail(user.id)"
                                        class="dropdown-item"
                                    >
                                        Resend verification mail
                                    </a>
                                    <a
                                        v-if="!user.currentSystemUser && canManage"
                                        :href="'/admin/user/current-roles/' + user.id"
                                        class="dropdown-item"
                                    >
                                        Roles
                                    </a>
                                    <!--                                    <a :href="'/admin/user/assignments/' + user.id" class="dropdown-item">-->
                                    <!--                                        Assignments-->
                                    <!--                                    </a>-->
                                    <a
                                        v-if="!user.systemUser && canManage"
                                        @click="deleteUser(user.id)"
                                        class="dropdown-item"
                                    >
                                        Delete
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "RolesIndexComponent",
    props: ["cpoList", "usersList", "superUser", "translations", "addUserRoute", "canManage"],
    data: function () {
        return {
            cpo: "",
            search: "",
        };
    },
    computed: {
        finalList() {
            if (!this.cpo && !this.search) {
                return this.usersList;
            }

            return this.usersList.filter(
                (x) =>
                    ((this.cpo && x.charge_point_operator_id === +this.cpo) || !this.cpo) &&
                    ((this.search && x.name.toLowerCase().includes(this.search.toLowerCase())) || !this.search)
            );
        },
    },
    methods: {
        deleteUser(id) {
            if (confirm("Are you sure you want to delete this role?")) {
                axios
                    .delete("/admin/user/delete/" + id)
                    .then((response) => {
                        if (response.data.success) {
                            alert("User deleted successfully");
                            window.location.reload();
                        } else {
                            alert("Something went wrong");
                        }
                    })
                    .catch((error) => {
                        if (error && error.response && error.response.data) {
                            alert(error.response.data.message);
                        } else {
                            alert("Something went wrong");
                            console.log(error);
                        }
                    });
            }
        },
        resendMail(id) {
            axios
                .put("/admin/user/resend-mail/" + id)
                .then((response) => {
                    if (response.data.success) {
                        alert("Verification mail sent successfully");
                        window.location.reload();
                    } else {
                        alert("Something went wrong");
                    }
                })
                .catch((error) => {
                    if (error && error.response && error.response.data) {
                        alert(error.response.data.message);
                    } else {
                        alert("Something went wrong");
                        console.log(error);
                    }
                });
        },
    },
};
</script>
