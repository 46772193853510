<template>
  <template v-if="pricingDetailsId">
    <hubject-pricing-power-modal-component
      :initial-current="modalCurrent"
      :initial-id="modalId"
      :initial-power="modalPower"
      :initial-pricing-type="modalPricingType"
      :initial-price="modalPrice"
      :initial-free-start="modalFreeStart"
      :initial-initial-fee="modalInitialFee"
      :initial-park-minute="modalParkMinute"
      :show-a-c3="showAC3"
      :title="modalTitle"
      :refresh="modalRefresh"
      @save="save"
    >
    </hubject-pricing-power-modal-component>
    <div class="row">
      <div class="col-12">
        <h3>Power options</h3>
        <hr />
        <table class="table table-striped border-bottom">
          <thead>
            <tr>
              <th>Power</th>
              <th>Current</th>
              <th>Pricing Type</th>
              <th>Price</th>
              <th>Initial Fee</th>
              <th>Free Start (kWh/minute)</th>
              <th>Park Minute</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="powerDetail in powerDetails" :key="powerDetail.id">
              <td>{{ powerDetail.power ?? '---' }}</td>
              <td>{{ powerDetail.current }}</td>
              <td>{{ powerDetail.pricing_type }}</td>
              <td>{{ (+powerDetail.price).toFixed(2) }}</td>
              <td>{{ (+powerDetail.initial_fee).toFixed(2) }}</td>
              <td>
                {{
                  powerDetail.pricing_type === 'per_minute'
                    ? (+powerDetail.free_start).toFixed(0)
                    : (+powerDetail.free_start).toFixed(2)
                }}
              </td>
              <td>{{ (+powerDetail.park_minute).toFixed(2) }}</td>
              <td>
                <button
                  v-if="
                    (isPerMinute && powerDetail.pricing_type === 'per_minute') ||
                    (!isPerMinute && powerDetail.pricing_type === 'per_kwh')
                  "
                  class="btn btn-sm btn-primary mr-2"
                  @click="
                    editPowerDetail(
                      powerDetail.id,
                      powerDetail.power,
                      powerDetail.current,
                      powerDetail.pricing_type,
                      powerDetail.price,
                      powerDetail.initial_fee,
                      powerDetail.free_start,
                      powerDetail.park_minute,
                    )
                  "
                >
                  Edit
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  @click="deletePowerDetail(powerDetail.id)"
                  v-if="powerDetail.power"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-right">
          <button class="btn btn-sm btn-primary" @click="addPowerDetail()">Add New Power Details</button>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
export default {
  props: {
    powerDetails: {
      type: Object,
      required: true,
    },
    pricingDetailsId: {
      type: Number,
      required: true,
    },
    powerDetailsId: {
      type: Number,
      required: true,
    },
    showAC3: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      modalCurrent: 'ac',
      modalPower: 0,
      modalId: -1,
      modalPricingType: this.isPerMinute ? 'per_minute' : 'per_kwh',
      modalPrice: '0',
      modalInitialFee: '0',
      modalFreeStart: '0',
      modalParkMinute: '0',
      modalTitle: '',
      modalRefresh: false,
    };
  },
  emits: ['savePowerDetail', 'deletePowerDetail'],
  computed: {
    isPerMinute() {
      if (!this.pricingDetailsId) {
        return false;
      }

      return Object.values(this.powerDetails).some(
        (powerDetail) => +powerDetail.price && powerDetail.pricing_type === 'per_minute',
      );
    },
  },
  methods: {
    deletePowerDetail(id) {
      if (!confirm('Are you sure you want to delete this power detail?')) {
        return;
      }

      this.$emit('deletePowerDetail', id);
    },
    editPowerDetail(id, power, current, pricingType, price, initialFee, freeStart, parkMinute) {
      this.modalPower = power;
      this.modalTitle = 'Edit Power Detail';
      this.modalId = id;
      this.modalCurrent = current;
      this.modalPricingType = pricingType;
      this.modalPrice = price;
      this.modalInitialFee = initialFee;
      this.modalFreeStart = freeStart;
      this.modalParkMinute = parkMinute;
      this.modalRefresh = !this.modalRefresh;
      $('#powerDetailsModal').modal('show');
    },
    save(id, power, current, pricingType, price, initialFee, freeStart, parkMinute) {
      this.$emit('savePowerDetail', id, power, current, pricingType, price, initialFee, freeStart, parkMinute);
    },
    addPowerDetail() {
      this.modalId = -1;
      this.modalPower = 22;
      this.modalCurrent = 'ac';
      this.modalTitle = 'Add Power Detail';
      this.modalPricingType = this.isPerMinute ? 'per_minute' : 'per_kwh';
      this.modalPrice = '0';
      this.modalInitialFee = '0';
      this.modalFreeStart = '0';
      this.modalParkMinute = '0';
      this.modalRefresh = !this.modalRefresh;
      $('#powerDetailsModal').modal('show');
    },
  },
};
</script>
