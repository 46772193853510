<template>
  <div class="main-app-overlay" v-if="showOverlay">
    <div class="lds-dual-ring"></div>
  </div>

  <form :method="method ?? 'POST'" @submit="submit" ref="form" novalidate v-bind="$attrs">
    <slot></slot>
  </form>
</template>

<script setup>
import { provide, ref } from 'vue';

const props = defineProps({
  method: String,
  question: String,
});

/**
 * @type {HTMLFormElement}
 */
const form = ref(null);
const isValidating = ref(false);
const showOverlay = ref(false);

provide('isValidating', isValidating);

function submit(event) {
  event.preventDefault();
  let submitAllowed = true;
  isValidating.value = true;
  if (window.formValidate) {
    submitAllowed = window.formValidate();
  }

  if (form.value.checkValidity() && submitAllowed !== false) {
    if (props.question) {
      if (!confirm(props.question)) {
        return;
      }
    }

    if (window.preSubmit) {
      window.preSubmit();
    }

    showOverlay.value = true;
    form.value.submit();
    return;
  }

  form.value.querySelectorAll(':invalid')[0]?.focus();
}
</script>
