<template>
  <div class="main-app-overlay" v-if="status">
    <div class="lds-dual-ring"></div>
  </div>
  <div class="modal fade" tabindex="-1" id="refundModal">
    <div class="modal-dialog modal-dialog-scrollable" role="document" style="max-width: 80%">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Fiscal refunds for account: {{ reference }}</h5>
          <button type="button" class="close" @click="closeModal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Reference</th>
                <th>Counter Number</th>
                <th>Invoice Number</th>
                <th>Amount</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in modalData" :key="item.id">
                <td>{{ item.response_reference_esir_id }}</td>
                <td>
                  <a :href="item.verificationUrl">{{ item.invoiceCounter }}</a>
                </td>
                <td>{{ item.invoiceNumber }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.created_at_string }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <slot :click="showRefunds"></slot>
</template>

<script setup>
import { ref } from 'vue';

const status = ref(false);
const modalData = ref([]);
const reference = ref('');
const props = defineProps({
  customerId: {
    type: Number,
    required: true,
  },
});
function showRefunds(fiscalType, fiscalReference, counterNumber) {
  const url =
    props.customerId === -1
      ? `/admin/reports/fiscal-refunds/${fiscalReference}`
      : `/admin/customer/fiscal-refunds/${props.customerId}/${fiscalType}/${fiscalReference}`;
  status.value = true;

  axios
    .get(url)
    .then((response) => {
      modalData.value = response.data;
      reference.value = counterNumber;
      $('#refundModal').modal('show');
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      status.value = false;
    });
}

function closeModal() {
  $('#refundModal').modal('hide');
}
</script>
