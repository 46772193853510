<template>
  <div
    class="modal fade"
    id="pricingDetailsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document" style="width: 600px; max-width: 600px">
      <form ref="power_form" @submit.prevent="save">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered table-striped">
              <tr>
                <th class="align-middle">Start Date</th>
                <td>
                  <input type="date" required v-model="startDate" class="form-control" />
                </td>
              </tr>
              <tr>
                <th class="align-middle">End Date</th>
                <td>
                  <input type="date" required v-model="endDate" class="form-control" />
                </td>
              </tr>
              <tr>
                <th class="align-middle">Start Time</th>
                <td>
                  <input type="time" required v-model="startTime" class="form-control" />
                </td>
              </tr>
              <tr>
                <th class="align-middle">
                  End Time<br /><small
                    ><em><sup>*</sup>&nbsp;end minute is included</em></small
                  >
                </th>
                <td class="align-middle">
                  <input type="time" required v-model="endTime" class="form-control" />
                </td>
              </tr>
              <tr>
                <th class="align-middle">Price Calculation Type</th>
                <td>
                  <select required v-model="calculationType" class="form-control">
                    <option value="daily">Daily</option>
                    <option value="inclusive">Inclusive</option>
                  </select>
                </td>
              </tr>
              <tr v-if="calculationType === 'daily'">
                <th class="align-middle">Days</th>
                <td class="align-middle" style="white-space: nowrap">
                  <template v-for="day in ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']">
                    <input
                      type="checkbox"
                      :checked="days[day]"
                      @change="(event) => (days[day] = event.target.checked)"
                      style="margin-right: 2px"
                    />
                    <label class="mr-2 mb-0 font-weight-normal">{{ day }}</label>
                  </template>
                </td>
              </tr>
              <tr>
                <th class="align-middle">Repeat</th>
                <td>
                  <input type="checkbox" v-model="repeat" />
                </td>
              </tr>
              <tr>
                <th class="align-middle">
                  Priority<small
                    ><br /><em style="white-space: nowrap"><sup>*</sup>&nbsp;lower number - greater priority</em></small
                  >
                </th>
                <td class="align-middle">
                  <input type="number" v-model="priority" step="1" min="0" max="1000" class="form-control" />
                </td>
              </tr>
            </table>
            <template v-if="!initialStartDate">
              <hr />
              <table class="table-striped table table-bordered">
                <tr>
                  <th></th>
                  <th class="text-center">AC</th>
                  <th v-if="showAC3" class="text-center">AC-3</th>
                  <th class="text-center">DC</th>
                </tr>
                <tr>
                  <th class="align-middle">Per kWh</th>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="acPerkWh" class="form-control" />
                  </td>
                  <td v-if="showAC3">
                    <input type="number" required min="0" step="0.01" v-model="ac3PerkWh" class="form-control" />
                  </td>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="dcPerkWh" class="form-control" />
                  </td>
                </tr>
                <tr>
                  <th class="align-middle">Per minute</th>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="acPerMinute" class="form-control" />
                  </td>
                  <td v-if="showAC3">
                    <input type="number" required min="0" step="0.01" v-model="ac3PerMinute" class="form-control" />
                  </td>
                  <td>
                    <input type="number" required min="0" step="0.01" v-model="dcPerMinute" class="form-control" />
                  </td>
                </tr>
              </table>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <input type="submit" class="btn btn-primary" value="Save" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialId: {
      type: Number,
      required: true,
    },
    initialStartDate: {
      type: String,
      required: true,
    },
    initialEndDate: {
      type: String,
      required: true,
    },
    initialStartTime: {
      type: String,
      required: true,
    },
    initialEndTime: {
      type: String,
      required: true,
    },
    initialCalculationType: {
      type: String,
      required: true,
    },
    initialPriority: {
      type: Number,
      required: true,
    },
    initialRepeat: {
      type: Boolean,
      required: true,
    },
    initialDays: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: true,
    },
    showAC3: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      calculationType: 'daily',
      priority: 0,
      repeat: false,
      days: {
        MON: false,
        TUE: false,
        WED: false,
        THU: false,
        FRI: false,
        SAT: false,
        SUN: false,
      },
      acPerMinute: 0,
      ac3PerMinute: 0,
      dcPerMinute: 0,
      acPerkWh: 0,
      ac3PerkWh: 0,
      dcPerkWh: 0,
    };
  },
  watch: {
    refresh: {
      immediate: true,
      handler: function () {
        this.startDate = this.initialStartDate;
        this.endDate = this.initialEndDate;
        this.startTime = this.initialStartTime;
        this.endTime = this.initialEndTime;
        this.calculationType = this.initialCalculationType;
        this.priority = this.initialPriority;
        this.repeat = this.initialRepeat;
        const day = this.initialDays == null ? [] : this.initialDays.split(',');
        this.days = {
          MON: day.includes('MON'),
          TUE: day.includes('TUE'),
          WED: day.includes('WED'),
          THU: day.includes('THU'),
          FRI: day.includes('FRI'),
          SAT: day.includes('SAT'),
          SUN: day.includes('SUN'),
        };
        this.acPerMinute = 0;
        this.ac3PerMinute = 0;
        this.dcPerMinute = 0;
        this.acPerkWh = 0;
        this.ac3PerkWh = 0;
        this.dcPerkWh = 0;
      },
    },
  },
  methods: {
    convertToTimeString(time, seconds = false) {
      return (
        ('' + time.getHours()).padStart(2, '0') +
        ':' +
        ('' + time.getMinutes()).padStart(2, '0') +
        (seconds ? ':' + ('' + time.getSeconds()).padStart(2, '0') : '')
      );
    },
    save() {
      if (!this.$refs.power_form.checkValidity()) {
        return;
      }

      const startDate = new Date(this.startDate + 'T00:00:00');
      const endDate = new Date(this.endDate + 'T00:00:00');

      if (startDate > endDate) {
        alert('Start date must not be after end date.');
        return;
      }

      const startTime = new Date(
        `${this.calculationType === 'inclusive' ? this.startDate : '1970-01-01'} ${this.startTime}`,
      );

      const endTime = new Date(`${this.calculationType === 'inclusive' ? this.endDate : '1970-01-01'} ${this.endTime}`);

      const startTimeString = this.convertToTimeString(startTime);
      const endTimeString = this.convertToTimeString(endTime);

      if (
        (this.calculationType === 'inclusive' && startTime > endTime) ||
        (this.calculationType === 'daily' && startTimeString > endTimeString)
      ) {
        alert('Start time must not be greater than end time.');
        return;
      }

      if (this.repeat && startDate.getFullYear() !== endDate.getFullYear()) {
        alert('When repeat is set, start date and end date must be in the same year.');
        return;
      }

      const days = [];
      for (const day in this.days) {
        if (this.days[day]) {
          days.push(day);
        }
      }

      if (this.calculationType === 'daily' && !days.length) {
        alert('Please select at least one day.');
        return;
      }

      if (!this.initialStartDate) {
        if (
          (this.acPerkWh || this.ac3PerkWh || this.dcPerkWh) &&
          (this.acPerMinute || this.ac3PerMinute || this.dcPerMinute)
        ) {
          alert('Please enter either per kWh or per minute price, not both.');
          return;
        }

        if (
          (!this.acPerkWh && !this.acPerMinute) ||
          (!this.dcPerkWh && !this.dcPerMinute) ||
          (!this.ac3PerkWh && !this.ac3PerMinute && this.showAC3)
        ) {
          alert('Please enter at least one price type for all positions.');
          return;
        }
      }

      this.$emit(
        'save',
        this.startDate,
        this.endDate,
        this.convertToTimeString(startTime, true),
        this.convertToTimeString(endTime, true),
        this.priority,
        this.repeat,
        this.calculationType === 'daily' ? days.join(',') : null,
        this.calculationType,
        this.initialId,
        this.acPerMinute,
        this.ac3PerMinute,
        this.dcPerMinute,
        this.acPerkWh,
        this.ac3PerkWh,
        this.dcPerkWh,
      );
    },
    close() {
      $('#pricingDetailsModal').modal('hide');
    },
  },
};
</script>
