<template>
  <div class="row">
    <div class="col-6 offset-3">
      <div class="justify-content-between d-flex mb-2">
        <div class="form-inline mb-2">
          <label for="filter" class="mr-2">Filter</label>
          <input id="filter" class="form-control" v-model="filter" />
        </div>
        <div>
          <!--          <button v-if="editingAllowed" class="btn btn-primary mr-2" @click="addOperator">Add Operator</button>-->
          <a :href="pricingRoute" class="btn btn-primary">Top Level Pricing</a>
        </div>
      </div>

      <table class="table table-striped" v-if="filteredList.length">
        <thead>
          <tr class="teal">
            <th scope="col">Operator Id</th>
            <th scope="col">Operator Name</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in filteredList">
            <td class="align-middle">{{ row.operator_id }}</td>
            <td class="align-middle">{{ row.operator_name }}</td>
            <td class="align-middle">{{ row.hubject_emp_operator_data[0].is_enabled ? 'Enabled' : 'Disabled' }}</td>
            <td class="align-middle">
              <button
                :class="[
                  'btn',
                  'btn-sm',
                  row.hubject_emp_operator_data[0].is_enabled ? 'btn-danger' : 'btn-success',
                  'mr-2',
                ]"
                @click="setStatus(row.id, !row.hubject_emp_operator_data[0].is_enabled)"
              >
                {{ row.hubject_emp_operator_data[0].is_enabled ? 'Disable' : 'Enable' }}
              </button>
              <button @click="goToManualPricing(row.id)" class="btn btn-sm btn-primary mr-2">Pricing</button>
              <button
                v-if="editingAllowed"
                :disabled="disabled[row.id]"
                class="btn btn-sm btn-warning"
                @click="loadData(row.id)"
              >
                Full Load
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <h5 v-else class="m-3">No Data</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: ['getRoute', 'statusRoute', 'loadRoute', 'addRoute', 'editingAllowed', 'pricingRoute', 'dynamicPricingList'],
  data: function () {
    return {
      list: [],
      filter: '',
      disabled: [],
    };
  },
  computed: {
    filteredList() {
      if (this.filter.length < 2) {
        return this.list;
      }

      return this.list.filter((item) => {
        return item.operator_id.toLowerCase().includes(this.filter.toLowerCase());
      });
    },
  },
  methods: {
    goToManualPricing: function (id) {
      console.log(id, this.dynamicPricingList);
      if (this.dynamicPricingList[id]) {
        if (
          !confirm(
            'This operator is already in dynamic pricing. If you enter manual pricing, ' +
              'manual pricing will take precedence. Do you want to proceed?',
          )
        ) {
          return;
        }
      }

      window.location.href = this.pricingRoute + '?hubject_emp_operator_id=' + id;
    },

    getData: function () {
      axios
        .get(this.getRoute)
        .then((response) => {
          this.list = response.data;
          this.disabled = new Array(this.list.length).fill(false);
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data.error);
          } else {
            alert(error);
            console.log(error);
          }
        });
    },
    setStatus: function (id, status) {
      axios
        .put(this.statusRoute, {
          operator_id: id,
          status: status,
        })
        .then(() => {
          const elem = this.list.find((item) => item.id === id);
          elem.hubject_emp_operator_data[0].is_enabled = status;
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data.error);
          } else {
            alert(error);
            console.log(error);
          }
        });
    },
    loadData: function (id) {
      this.disabled[id] = true;
      axios
        .put(this.loadRoute, {
          operator_id: id,
        })
        .then(() => {
          alert('Load job successfully dispatched in background');
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data.error);
          } else {
            alert(error);
            console.log(error);
          }
        });
    },
    addOperator: function () {
      const id = prompt('Please enter the operator id');
      if (!id) {
        alert('Invalid operator id');
        return;
      }

      axios
        .put(this.addRoute, {
          operator_id: id,
        })
        .then(() => {
          alert('New operator successfully added');
          location.reload();
        })
        .catch((error) => {
          if (error.response) {
            alert(error.response.data.error);
          } else {
            alert(error);
            console.log(error);
          }
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
