<template>
  <div
    class="modal fade"
    id="powerDetailsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form ref="power_form" @submit.prevent="save">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered table-striped">
              <tr v-if="initialId === -1 || power">
                <th>Power</th>
                <td>
                  <input type="number" required min="1" step="1" v-model="power" class="form-control" />
                </td>
              </tr>
              <tr>
                <th>Current</th>
                <td>
                  <select required v-model="current" class="form-control" :disabled="initialId !== -1 && !power">
                    <option value="ac">AC</option>
                    <option v-if="showAC3" value="ac3">AC-3</option>
                    <option value="dc">DC</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Pricing Type</th>
                <td>
                  <select required v-model="pricingType" class="form-control" disabled>
                    <option value="per_minute">Per Minute</option>
                    <option value="per_kwh">Per kWh</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>Price</th>
                <td>
                  <input type="number" required min="0" step="0.01" v-model="price" class="form-control" />
                </td>
              </tr>
              <tr>
                <th>Initial Fee</th>
                <td>
                  <input type="number" required min="0" step="0.01" v-model="initialFee" class="form-control" />
                </td>
              </tr>
              <tr>
                <th>{{ pricingType === 'perMinute' ? 'First Free Minutes' : 'First Free kWh' }}</th>
                <td>
                  <input
                    type="number"
                    required
                    min="0"
                    :step="pricingType === 'perMinute' ? 1 : 0.01"
                    v-model="freeStart"
                    class="form-control"
                  />
                </td>
              </tr>
              <tr>
                <th>Park Minute Price</th>
                <td>
                  <input type="number" required min="0" step="0.01" v-model="parkMinute" class="form-control" />
                </td>
              </tr>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Close</button>
            <input type="submit" class="btn btn-primary" value="Save" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialPower: {
      type: Number,
      required: false,
    },
    initialCurrent: {
      type: String,
      required: true,
    },
    initialId: {
      type: Number,
      required: true,
    },
    initialPricingType: {
      type: String,
      required: true,
    },
    initialPrice: {
      type: String,
      required: true,
    },
    initialInitialFee: {
      type: String,
      required: true,
    },
    initialFreeStart: {
      type: String,
      required: true,
    },
    initialParkMinute: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: true,
    },
    showAC3: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      power: this.initialPower,
      current: this.initialCurrent,
      pricingType: this.initialPricingType,
      initialFee: this.initialFee,
      freeStart: this.freeStart,
      parkMinute: this.parkMinute,
      price: this.initialPrice,
    };
  },
  watch: {
    refresh: function () {
      this.power = this.initialPower;
      this.current = this.initialCurrent;
      this.pricingType = this.initialPricingType;
      this.price = this.initialPrice;
      this.initialFee = this.initialInitialFee;
      this.freeStart = this.initialFreeStart;
      this.parkMinute = this.initialParkMinute;
    },
  },
  methods: {
    save() {
      if (!this.$refs.power_form.checkValidity()) {
        return;
      }

      if (!this.price && !this.initialFee && !this.parkMinute) {
        alert('At least one of price, initial fee, or park minute price must be set.');
        return;
      }

      this.$emit(
        'save',
        this.initialId,
        this.power,
        this.current,
        this.pricingType,
        this.price,
        this.initialFee,
        this.freeStart,
        this.parkMinute,
      );
    },
    close() {
      $('#powerDetailsModal').modal('hide');
    },
  },
};
</script>
