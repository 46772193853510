<template>
  <div class="rounded border p-3 mb-3">
    <h5>Direction information</h5>
    <input v-for="id in removedDirections" type="hidden" name="removed_directions[]" :value="id" />
    <template v-for="direction in addedDirections">
      <input type="hidden" name="added_directions[]" :value="direction.directions" />
      <input type="hidden" name="added_languages[]" :value="direction.language" />
    </template>

    <div class="form-group" v-if="addedDirections.length || startDirections.length">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="border-top: none">Language</th>
            <th style="border-top: none">Directions</th>
            <th class="text-center" style="border-top: none">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="direction in currentDirections">
            <td>{{ languages[direction.language] }}</td>
            <td>{{ direction.directions }}</td>
            <td class="text-center">
              <button type="button" class="btn btn-danger" @click="removeDirection(direction.id)">Remove</button>
            </td>
          </tr>
          <tr v-for="direction in addedDirections">
            <td>{{ languages[direction.language] }}</td>
            <td>{{ direction.directions }}</td>
            <td class="text-center">
              <button type="button" class="btn btn-danger" @click="removeAddedDirection(direction.id)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="filteredLanguages.length">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label class="teal" for="language">Language</label>
            <select v-model="language" class="form-control" id="language">
              <option value="">Select language</option>
              <option v-for="language in filteredLanguages" :value="language[0]">{{ language[1] }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-9">
          <div class="form-group">
            <label class="teal" for="directions">Direction</label>
            <input type="text" v-model="direction" class="form-control" id="directions" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" @click="addDirection()">Add new direction</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['directions', 'oldRemovedDirections', 'oldAddedDirections'],
  data() {
    return {
      languages: { en: 'English', rs: 'Serbian', de: 'German', fr: 'France', it: 'Italian', es: 'Spanish' },
      removedDirections: this.oldRemovedDirections.map((directionId) => parseInt(directionId)),
      startDirections: this.directions,
      addedDirections: this.oldAddedDirections,
      direction: '',
      language: '',
      addedId: this.oldAddedDirections.length + 1,
    };
  },
  computed: {
    filteredLanguages() {
      return Object.entries(this.languages).filter(
        (language) =>
          !this.addedDirections.some((direction) => direction.language === language[0]) &&
          !this.startDirections.some((direction) => direction.language === language[0]),
      );
    },
    currentDirections() {
      return this.startDirections.filter((direction) => !this.removedDirections.includes(direction.id));
    },
  },
  methods: {
    removeDirection(id) {
      this.removedDirections.push(id);
      this.startDirections = this.startDirections.filter((direction) => direction.id !== id);
    },
    removeAddedDirection(id) {
      this.addedDirections = this.addedDirections.filter((direction) => direction.id !== id);
    },
    addDirection() {
      if (this.direction && this.language) {
        this.addedDirections.push({
          id: this.addedId++,
          directions: this.direction,
          language: this.language,
        });

        this.direction = '';
        this.language = '';
      } else {
        alert('Please fill in all fields');
      }
    },
  },
};
</script>
