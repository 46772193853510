<template>
  <table class="table table-striped table-bordered">
    <thead>
      <tr>
        <th>Key</th>
        <th>Readonly</th>
        <th>Value</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in configData">
        <td>{{ row.key }}</td>
        <td>{{ row.readOnly ? 'Yes' : 'No' }}</td>
        <td>
          <template v-if="!resultData[row.key]">
            <span v-if="!inProgress || this.key !== row.key">{{ row.value }}</span>
            <template v-if="inProgress && this.key === row.key">
              <input v-if="typeof this.editValue === 'string'" type="text" class="form-control" v-model="editValue" />
              <input
                v-if="typeof this.editValue === 'number'"
                type="number"
                step="1"
                class="form-control"
                v-model="editValue"
              />
              <input
                v-if="typeof this.editValue === 'boolean'"
                type="checkbox"
                class="form-control"
                style="width: 20px"
                v-model="editValue"
              />
            </template>
          </template>
          <span v-else :style="calculateResultDataStyle(row.key)">
            {{ calculatePositiveStatus(row.key) ? resultData[row.key].value : row.value }}
          </span>
        </td>
        <td>
          <template v-if="!resultData[row.key]">
            <button
              v-if="!row.readOnly && (!inProgress || this.key !== row.key)"
              :disabled="inProgress || callInProgress"
              class="btn btn-default"
              @click="editConfig(row)"
            >
              Edit
            </button>
            <template v-if="inProgress && this.key === row.key">
              <button class="btn btn-danger" @click="saveConfig()">Save</button>
              <button class="btn btn-default ml-2" @click="cancelConfig()">Close</button>
            </template>
          </template>
          <span v-else>{{ resultData[row.key].status }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'ConfigurationComponent',
  props: ['configData', 'callInProgress', 'resultData'],
  data: function () {
    return {
      inProgress: false,
      key: '',
      editValue: null,
    };
  },
  methods: {
    editConfig: function (row) {
      this.inProgress = true;
      this.key = row.key;
      this.editValue = row.value;
    },
    saveConfig: function () {
      if (!confirm('Are you sure you want to save this configuration?')) {
        return;
      }

      this.$emit('save-config', {
        key: this.key,
        value: this.editValue,
      });

      this.cancelConfig();
    },
    cancelConfig: function () {
      this.inProgress = false;
      this.key = '';
      this.editValue = null;
    },
    calculateResultDataStyle: function (key) {
      if (!this.resultData[key] || this.resultData[key].status === 'in progress') {
        return {};
      }

      if (this.resultData[key].status === 'Accepted') {
        return {
          fontWeight: 'bold',
          color: 'green',
        };
      }

      if (this.resultData[key].status === 'RebootRequired') {
        return {
          fontWeight: 'bold',
          color: 'yellow',
        };
      }

      return {
        fontWeight: 'bold',
        color: 'red',
      };
    },
    calculatePositiveStatus: function (key) {
      return this.resultData[key].status === 'Accepted' || this.resultData[key].status === 'RebootRequired';
    },
  },
};
</script>
