<template>
    <div class="row">
        <div class="col-md-4 border-right">
            <h3>Assignment object</h3>
            <hr />
            <div class="form-group">
                <label class="form-label">Assignment level</label>
                <select class="form-control" v-model="level" @change="changeAssignmentLevel($event.target.value)">
                    <option value="">Please select</option>
                    <option value="1" v-if="Object.keys(emp).length">Energy mobility provider</option>
                    <option value="2">Charge point operator</option>
                    <option value="3">Location</option>
                    <option value="4">Charge point</option>
                </select>
            </div>
            <div class="form-group">
                <label class="form-label">Assignment objects</label>
                <v-select
                    class="vue-select"
                    v-if="objects.length"
                    :options="objects"
                    :reduce="(x) => x.id"
                    :placeholder="'Please select'"
                    v-model="selectedObject"
                    @update:modelValue="changeObject($event)"
                >
                </v-select>
                <h6 v-else>No data for specified level</h6>
            </div>
        </div>
        <div class="col-md-4 border-right">
            <h3>Inherited assignments</h3>
            <hr />
            <div v-if="inherited.length">
                <div v-for="item in inherited" class="card" style="border-width: 1px">
                    <AssignmentPrograms :item="item" :remove="false" />
                </div>
            </div>
            <div v-else>
                <h6>No inherited records</h6>
            </div>
            <h3 class="mt-5">Direct assignment</h3>
            <hr />
            <div v-if="direct.length">
                <div v-for="item in direct" class="card" style="border-width: 1px">
                    <AssignmentPrograms :item="item" @removeAssignment="removeAssignment" :remove="true" />
                </div>
            </div>
            <div v-else>
                <h6>No direct records</h6>
            </div>
        </div>
        <div class="col-md-4 border-right">
            <h3>All programs</h3>
            <hr />
            <div v-if="listedPrograms.length">
                <div v-for="program in listedPrograms" class="card" style="border-width: 1px">
                    <div class="card-body">
                        <div class="row">
                            <div
                                class="col-8 align-items-center d-flex"
                                style="cursor: pointer"
                                @click="gotoProgram(program)"
                            >
                                <dt>{{ program.name }}</dt>
                            </div>
                            <div class="col-4 text-right" v-if="!direct.length">
                                <div class="btn btn-danger" @click="addAssignment(program)">Add</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <h6>No programs to assign</h6>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
:deep(.v-select.vs--single.vs--searchable) {
    background: #ffffff;
}

:deep(.vs__dropdown-toggle) {
    border-color: #ced4da;
}

:deep(.vs__dropdown-menu) {
    border-color: #ced4da;
}

:deep(.vs--open) {
    .vs__dropdown-toggle {
        border-color: #80bdff;
        background-color: #fafafa;
    }

    .vs__dropdown-menu {
        border-color: #80bdff;
        background-color: #ffffff;
    }
}

:deep(:root) {
    --vs-controls-color: #495057;

    --vs-dropdown-color: #495057;
    --vs-search-input-color: #495057;

    --vs-dropdown-option--active-bg: #80bdff;
    --vs-dropdown-option--active-color: #ffffff;
}
</style>

<script>
import AssignmentPrograms from './AssignmentPrograms.vue';

export default {
    name: 'AssignmentLayout',
    components: { AssignmentPrograms },
    props: {
        emp: {
            type: Object,
        },
        cpo: {
            type: Object,
        },
        locations: {
            type: Object,
        },
        locationIds: {
            type: Object,
        },
        cp: {
            type: Object,
        },
        cpIds: {
            type: Object,
        },
        routes: {
            type: Object,
        },
        programs: {
            type: Object,
        },
    },
    data() {
        return {
            level: '',
            selectedObject: null,
            objects: {},
            direct: [],
            inherited: [],
            listedPrograms: [],
        };
    },
    methods: {
        changeAssignmentLevel(level) {
            if (+level === 1) {
                this.setAssignmentLevel(this.emp);
            } else if (+level === 2) {
                this.setAssignmentLevel(this.cpo);
            } else if (+level === 3) {
                this.setAssignmentLevel(this.locations);
            } else if (+level === 4) {
                this.setAssignmentLevel(this.cp);
            }
        },
        setAssignmentLevel(data) {
            const result = [];
            result.push(
                ...Object.entries(data).map((x) => ({
                    id: x[0],
                    label: x[1],
                }))
            );

            this.selectedObject = null;
            this.direct = [];
            this.inherited = [];
            this.objects = result;
            this.listedPrograms = [];
            history.replaceState(null, null, window.location.pathname);
        },
        changeObject(data) {
            axios
                .get(this.routes['getAssignmentForObject'], {
                    params: {
                        object_id: data,
                        object_level: this.level,
                    },
                })
                .then((response) => {
                    if (+this.level === 1) {
                        this.listedPrograms = this.programs.emp;
                    } else if (+this.level === 2) {
                        this.listedPrograms = this.programs.cpo.filter(
                            (x) => x.charge_point_operator_id === +this.selectedObject
                        );
                    } else if (+this.level === 3) {
                        this.listedPrograms = this.programs.cpo.filter((x) =>
                            this.locationIds[this.selectedObject].includes(x.charge_point_operator_id)
                        );
                    } else if (+this.level === 4) {
                        this.listedPrograms = this.programs.cpo.filter(
                            (x) => x.charge_point_operator_id === this.cpIds[this.selectedObject]
                        );
                    }

                    this.direct = response.data.direct.sort((a, b) => a.calculated_priority - b.calculated_priority);
                    this.inherited = response.data.inherited.sort(
                        (a, b) => a.calculated_priority - b.calculated_priority
                    );
                })
                .catch((error) => {
                    if (error.response) {
                        alert(
                            'Something went wrong: ' +
                                (error.response.data.message ??
                                    error.response.data.error_message ??
                                    error.response.status)
                        );
                    } else {
                        alert('Something went wrong: ' + error);
                    }
                });
        },
        removeAssignment(item) {
            const message =
                +this.level === 1
                    ? 'This will remove EMP level program. Charge Points may stay without pricing. Are you sure?'
                    : 'Are you sure?';

            if (!confirm(message)) {
                return;
            }

            axios
                .delete(this.routes['removePrice'], {
                    data: {
                        hubject_pricing_id: item.id,
                    },
                })
                .then(() => {
                    this.direct = [];
                })
                .catch((error) => {
                    if (error.response) {
                        alert(
                            'Something went wrong: ' +
                                (error.response.data.message ??
                                    error.response.data.error_message ??
                                    error.response.status)
                        );
                    } else {
                        alert('Something went wrong: ' + error);
                    }
                });
        },
        addAssignment(program) {
            axios
                .post(this.routes['assignPrice'], {
                    program_id: program.id,
                    charge_point_operator_id: +this.level === 2 ? this.selectedObject : null,
                    location_id: +this.level === 3 ? this.selectedObject : null,
                    charge_point_id: +this.level === 4 ? this.selectedObject : null,
                })
                .then((response) => {
                    this.direct = [
                        {
                            calculated_priority: response.data.calculated_priority,
                            charge_point_id: response.data.charge_point_id,
                            charge_point_operator_id: response.data.charge_point_operator_id,
                            energy_mobility_provider_id: response.data.energy_mobility_provider_id,
                            hubject_pricing_program: {
                                name: program.name,
                            },
                            id: response.data.id,
                            location_id: response.data.location_id,
                        },
                    ];
                    console.log(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        alert(
                            'Something went wrong: ' +
                                (error.response.data.message ??
                                    error.response.data.error_message ??
                                    error.response.status)
                        );
                    } else {
                        alert('Something went wrong: ' + error);
                    }
                });
        },
        gotoProgram(program) {
            history.pushState(
                null,
                null,
                window.location.pathname + '?level=' + this.level + '&object-id=' + this.selectedObject
            );
            window.location.href = this.routes['showProgram'] + '?program-id=' + program.id;
        },
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const level = urlParams.get('level');
        const objectId = urlParams.get('object-id');
        if (level && objectId) {
            this.level = level;
            this.changeAssignmentLevel(level);
            this.selectedObject = objectId;
            this.changeObject(objectId);
        }
    },
};
</script>
