<template>
  <hubject-program-model-component
    :title="modalTitle"
    :refresh="modalRefresh"
    :initial-id="modalId"
    :initial-name="modalName"
    :initial-cpo-id="modalCpoId"
    :charge-point-operators="chargePointOperators"
    :charge-point-restricted-id="chargePointRestrictedId"
    @save="saveProgram"
  ></hubject-program-model-component>
  <div class="row">
    <div class="col-md-4 border-right">
      <template v-if="Object.keys(programDetails).length">
        <h3>Program list</h3>
        <hr />
        <table class="table table-striped border-bottom">
          <thead>
            <tr>
              <th>Program</th>
              <th>Charge Point Operator</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="program in programDetails" :key="program.id" @click.capture="setProgramId(program.id)">
              <td style="cursor: pointer">{{ program.name }}</td>
              <td style="cursor: pointer">
                {{ program.charge_point_operator_id ? chargePointOperators[program.charge_point_operator_id] : '---' }}
              </td>
              <td>
                <button class="btn btn-sm btn-primary mr-2" @click="editProgram(program.id)">Edit</button>
                <button class="btn btn-sm btn-danger" @click="deleteProgram(program.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <div class="text-right">
        <button class="btn btn-sm btn-primary" @click="addProgram()">Add New Program</button>
      </div>
    </div>
    <div class="col-md-8">
      <hubject-pricing-details-component
        :program-id="programId"
        :pricing-details="programId ? programDetails[programId].hubject_pricing_details : {}"
        :pricing-details-id="pricingDetailsId"
        :power-details-id="powerDetailsId"
        :program-name="programName"
        @save-power-detail="savePowerDetail"
        @save-pricing-detail="savePricingDetail"
        @pricing-details-change="(id) => (pricingDetailsId = id)"
        @delete-power-detail="deletePowerDetail"
        @delete-pricing-detail="deletePricingDetail"
      ></hubject-pricing-details-component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialProgramDetails: {
      type: Array,
      required: true,
    },
    chargePointOperators: {
      type: Object,
      required: true,
    },
    chargePointRestrictedId: {
      type: Number,
      required: false,
    },
    routes: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    const programDetails = {};
    for (const programDetail of this.initialProgramDetails) {
      programDetails[programDetail.id] = { ...programDetail };
      programDetails[programDetail.id].hubject_pricing_details = {};
      for (const pricingDetail of programDetail.hubject_pricing_details) {
        programDetails[programDetail.id].hubject_pricing_details[pricingDetail.id] = { ...pricingDetail };
        programDetails[programDetail.id].hubject_pricing_details[pricingDetail.id].repeat = !!pricingDetail.repeat;
        programDetails[programDetail.id].hubject_pricing_details[pricingDetail.id].hubject_pricing_power_details = {};
        for (const powerDetail of pricingDetail.hubject_pricing_power_details) {
          programDetails[programDetail.id].hubject_pricing_details[pricingDetail.id].hubject_pricing_power_details[
            powerDetail.id
          ] = powerDetail;
        }
      }
    }

    return {
      programId: null,
      programName: null,
      programDetails: programDetails,
      pricingDetailsId: 0,
      powerDetailsId: 0,
      modalName: '',
      modalCpoId: -1,
      modalId: -1,
      modalRefresh: false,
      modalTitle: 'Add Program Detail',
    };
  },
  methods: {
    setProgramId(id) {
      this.programId = id;
      this.programName = this.programDetails[id].name;
      this.pricingDetailsId = +Object.keys(this.programDetails[id].hubject_pricing_details)[0];
    },
    addProgram() {
      this.modalId = -1;
      this.modalName = '';
      this.modalCpoId = null;
      this.modalRefresh = !this.modalRefresh;
      this.modalTitle = 'Add Program Detail';
      $('#programDetailsModal').modal('show');
    },
    editProgram(id) {
      this.modalId = id;
      this.modalName = this.programDetails[id].name;
      this.modalRefresh = !this.modalRefresh;
      this.modalTitle = 'Edit Program Detail';
      $('#programDetailsModal').modal('show');
    },
    createPowerDetailObject(id, current, pricingType, price) {
      return {
        id: id,
        power: null,
        current: current,
        pricing_type: pricingType,
        price: '' + price,
        initial_fee: '' + 0,
        free_start: '' + 0,
        park_minute: '' + 0,
      };
    },
    saveProgram(name, cpoId, id, chargePointRestrictedId, acPerMinute, _1, dcPerMinute, acPerkWh, _2, dcPerkWh) {
      if (id === -1) {
        this.sendRequest(
          this.routes.createProgram,
          'POST',
          {
            name: name,
            charge_point_operator_id: chargePointRestrictedId ?? cpoId,
            ac_per_minute: acPerMinute,
            dc_per_minute: dcPerMinute,
            ac_per_kwh: acPerkWh,
            dc_per_kwh: dcPerkWh,
          },
          (response) => {
            this.programDetails[response.program_id] = {
              id: response.program_id,
              name: name,
              charge_point_operator_id: chargePointRestrictedId ?? cpoId,
              hubject_pricing_details: {
                [response.pricing_id]: {
                  id: response.pricing_id,
                  start_date: null,
                  end_date: null,
                  start_time: null,
                  end_time: null,
                  calculation_type: 'daily',
                  priority: 1000,
                  repeat: false,
                  days: null,
                  hubject_pricing_power_details: {
                    [response.ac_per_kwh]: this.createPowerDetailObject(response.ac_per_kwh, 'ac', 'per_kwh', acPerkWh),
                    [response.dc_per_kwh]: this.createPowerDetailObject(response.dc_per_kwh, 'dc', 'per_kwh', dcPerkWh),
                    [response.ac_per_minute]: this.createPowerDetailObject(
                      response.ac_per_minute,
                      'ac',
                      'per_minute',
                      acPerMinute,
                    ),
                    [response.dc_per_minute]: this.createPowerDetailObject(
                      response.dc_per_minute,
                      'dc',
                      'per_minute',
                      dcPerMinute,
                    ),
                  },
                },
              },
              charge_point_operator: response.charge_point_operator,
            };

            this.programId = response.program_id;
            this.programName = name;
            this.pricingDetailsId = response.pricing_id;

            $('#programDetailsModal').modal('hide');
          },
        );
      } else {
        this.sendRequest(
          this.routes.updateProgram,
          'PUT',
          {
            name: name,
            program_id: id,
          },
          () => {
            this.programDetails[this.programId].name = name;
            $('#programDetailsModal').modal('hide');
          },
        );
      }
    },
    deleteProgram(id) {
      if (!confirm('Are you sure you want to delete this program?')) {
        return;
      }

      this.sendRequest(
        this.routes.deleteProgram,
        'DELETE',
        {
          program_id: id,
        },
        () => {
          this.programId = null;
          this.programName = null;
          delete this.programDetails[id];
        },
      );
    },
    savePricingDetail(
      startDate,
      endDate,
      startTime,
      endTime,
      priority,
      repeat,
      days,
      calculationType,
      id,
      acPerMinute,
      ac3PerMinute,
      dcPerMinute,
      acPerkWh,
      ac3PerkWh,
      dcPerkWh,
    ) {
      if (id === -1) {
        this.sendRequest(
          this.routes.createPricingDetails,
          'POST',
          {
            program_id: this.programId,
            start_date: startDate,
            end_date: endDate,
            start_time: startTime,
            end_time: endTime,
            priority: priority,
            repeat: repeat,
            days: days,
            calculation_type: calculationType,
            ac_per_minute: acPerMinute,
            ac_3_per_minute: ac3PerMinute,
            dc_per_minute: dcPerMinute,
            ac_per_kwh: acPerkWh,
            ac_3_per_kwh: ac3PerkWh,
            dc_per_kwh: dcPerkWh,
          },
          (response) => {
            this.programDetails[this.programId].hubject_pricing_details[response.pricing_id] = {
              id: response.pricing_id,
              start_date: startDate,
              end_date: endDate,
              start_time: startTime,
              end_time: endTime,
              priority: priority,
              repeat: repeat,
              days: days,
              calculation_type: calculationType,
              hubject_pricing_power_details: {
                [response.ac_per_kwh]: this.createPowerDetailObject(response.ac_per_kwh, 'ac', 'per_kwh', acPerkWh),
                [response.dc_per_kwh]: this.createPowerDetailObject(response.dc_per_kwh, 'dc', 'per_kwh', dcPerkWh),
                [response.ac_per_minute]: this.createPowerDetailObject(
                  response.ac_per_minute,
                  'ac',
                  'per_minute',
                  acPerMinute,
                ),
                [response.dc_per_minute]: this.createPowerDetailObject(
                  response.dc_per_minute,
                  'dc',
                  'per_minute',
                  dcPerMinute,
                ),
              },
            };
            this.pricingDetailsId = response.pricing_id;
            this.powerDetailsId = 0;

            $('#pricingDetailsModal').modal('hide');
          },
        );
      } else {
        this.sendRequest(
          this.routes.updatePricingDetails,
          'PUT',
          {
            pricing_id: id,
            start_date: startDate,
            end_date: endDate,
            start_time: startTime,
            end_time: endTime,
            priority: priority,
            repeat: repeat,
            days: days,
            calculation_type: calculationType,
          },
          () => {
            this.programDetails[this.programId].hubject_pricing_details[id] = {
              id: id,
              start_date: startDate,
              end_date: endDate,
              start_time: startTime,
              end_time: endTime,
              priority: priority,
              repeat: repeat,
              days: days,
              calculation_type: calculationType,
              hubject_pricing_power_details:
                this.programDetails[this.programId].hubject_pricing_details[id].hubject_pricing_power_details,
            };

            $('#pricingDetailsModal').modal('hide');
          },
        );
      }
    },
    deletePricingDetail(id) {
      this.sendRequest(
        this.routes.deletePricingDetails,
        'DELETE',
        {
          pricing_id: id,
        },
        () => {
          this.pricingDetailsId = 0;
          delete this.programDetails[this.programId].hubject_pricing_details[id];
        },
      );
    },
    savePowerDetail(id, power, current, pricingType, price, initialFee, freeStart, parkMinute, pricingDetailsId) {
      if (id === -1) {
        this.sendRequest(
          this.routes.createPowerDetails,
          'POST',
          {
            pricing_id: pricingDetailsId,
            power: power,
            current: current,
            pricing_type: pricingType,
            price: '' + price,
            initial_fee: '' + initialFee,
            free_start: '' + freeStart,
            park_minute: '' + parkMinute,
          },
          (response) => {
            this.programDetails[this.programId].hubject_pricing_details[pricingDetailsId].hubject_pricing_power_details[
              response.power_id
            ] = {
              id: response.power_id,
              power: power,
              current: current,
              pricing_type: pricingType,
              price: '' + price,
              initial_fee: '' + initialFee,
              free_start: '' + freeStart,
              park_minute: '' + parkMinute,
            };
            this.powerDetailsId = response.power_id;

            $('#powerDetailsModal').modal('hide');
          },
        );
      } else {
        this.sendRequest(
          this.routes.updatePowerDetails,
          'PUT',
          {
            power_id: id,
            power: power,
            current: current,
            pricing_type: pricingType,
            price: price,
            initial_fee: initialFee,
            free_start: freeStart,
            park_minute: parkMinute,
          },
          () => {
            this.programDetails[this.programId].hubject_pricing_details[pricingDetailsId].hubject_pricing_power_details[
              id
            ] = {
              id: id,
              power: power,
              current: current,
              pricing_type: pricingType,
              price: price,
              initial_fee: initialFee,
              free_start: freeStart,
              park_minute: parkMinute,
            };

            $('#powerDetailsModal').modal('hide');
          },
        );
      }
    },
    deletePowerDetail(id, pricingDetailsId) {
      this.sendRequest(
        this.routes.deletePowerDetails,
        'DELETE',
        {
          power_id: id,
        },
        () => {
          delete this.programDetails[this.programId].hubject_pricing_details[pricingDetailsId]
            .hubject_pricing_power_details[id];
        },
      );
    },
    sendRequest(url, method, data, callback) {
      axios({
        method: method,
        url: url,
        data: data,
      })
        .then((response) => {
          callback(response.data);
        })
        .catch((error) => {
          if (error.response) {
            alert('Something went wrong: ' + (error.response.data.message ?? error.response.data.error_message));
          } else {
            alert('Something went wrong: ' + error);
          }
        });
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('program-id');
    if (myParam) {
      this.setProgramId(+myParam);
    }
  },
};
</script>
