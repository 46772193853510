<template>
  <div class="col-md-3">
    <div class="form-group">
      <label for="cpo-id" class="mr-2">Charge Point Operator</label>
      <select
        name="cpo-id"
        id="cpo-id"
        class="form-control mr-2"
        v-model="selected_cpo_id"
        @change="selected_cp_id = -1"
      >
        <option value="-1">All</option>
        <option v-for="item in charge_point_operator" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label for="cp-id" class="mr-2">Charge Point</label>
      <select name="cp-id" id="cpo-id" class="form-control mr-2" v-model="selected_cp_id">
        <option value="-1">All</option>
        <option v-for="item in charge_points" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  charge_point_operator: Array,
  cpo_id: Number,
  cp_id: Number,
});

const selected_cpo_id = ref(props.cpo_id);
const selected_cp_id = ref(props.cp_id);

const charge_points = computed(() => {
  if (selected_cpo_id.value === -1) {
    return [];
  }

  const cpo = props.charge_point_operator.find((item) => item.id === selected_cpo_id.value);
  return cpo?.charge_points ?? [];
});
</script>
