<template>
  <div class="rounded border p-3 mb-3">
    <h5>Additional images</h5>
    <input v-for="id in removedImages" type="hidden" name="removed_images[]" :value="id" />
    <div class="form-group">
      <div class="row align-items-stretch">
        <div class="col-md-4" v-for="image in currentImages">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex flex-column justify-content-between h-100">
                <div>
                  <img :src="image.url" :alt="image.description" class="img-fluid" />
                  <div class="text-center py-2" style="flex-basis: 80px">
                    {{ image.description ?? '--- No description provided ---' }}
                  </div>
                </div>
                <div class="flex-grow-0 flex-shrink-0">
                  <hr />
                  <button
                    type="button"
                    class="btn btn-danger mt-1 ml-auto flex-grow-0 flex-shrink-0"
                    @click="removeImage(image.id)"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-for="image in addedImages">
          <div class="card h-100">
            <div class="card-body">
              <div class="d-flex flex-column justify-content-between h-100">
                <div>
                  <input type="hidden" :value="image.url" name="new_images[]" />
                  <input
                    type="hidden"
                    :value="image.description ?? '--- No description provided ---'"
                    name="new_descriptions[]"
                  />
                  <img :src="image.url" :alt="image.description" class="img-fluid" />
                  <div class="text-center py-2">
                    {{ image.description ?? '--- No description provided ---' }}
                  </div>
                </div>
                <div class="flex-grow-0 flex-shrink-0">
                  <hr />
                  <button
                    type="button"
                    class="btn btn-danger mt-1 ml-auto flex-grow-0 flex-shrink-0"
                    @click="removeAddedImage(image.id)"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="form-group">
        <label class="teal" for="images">New Image</label>
        <input ref="new_image" type="file" name="images[]" id="images" class="form-control" accept="image/*" />
      </div>
      <div class="form-group">
        <label class="teal" for="description">Description</label>
        <input type="text" v-model="description" class="form-control" id="description" />
      </div>
      <button type="button" class="btn btn-primary" @click="addImage()">Add new image</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['images', 'oldRemovedImages', 'oldAddedImages'],
  data() {
    return {
      removedImages: this.oldRemovedImages.map((imageId) => parseInt(imageId)),
      startImages: this.images,
      addedImages: this.oldAddedImages,
      description: '',
      addedId: 0,
    };
  },
  computed: {
    currentImages() {
      return this.startImages.filter((image) => !this.removedImages.includes(image.id));
    },
  },
  methods: {
    removeImage(id) {
      this.removedImages.push(id);
      this.startImages = this.startImages.filter((image) => image.id !== id);
    },
    removeAddedImage(id) {
      this.addedImages = this.addedImages.filter((image) => image.id !== id);
    },
    addImage() {
      const input = this.$refs.new_image;
      const _that = this;
      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = function (e) {
          _that.addedImages.push({
            url: e.target.result,
            description: _that.description ? _that.description : input.files[0].name,
            id: _that.addedId++,
          });

          input.value = '';
          _that.description = '';
        };

        reader.onerror = function (e) {
          alert('Error: ' + e + '. Please try again.');
        };

        reader.readAsDataURL(input.files[0]);
      } else {
        alert('Please select an image');
      }
    },
  },
};
</script>
