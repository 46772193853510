<template>
  <div class="form-group">
    <label class="teal" v-if="label" :for="formName">{{ label }}</label>
    <select
      :disabled="disabled"
      ref="input_elem"
      v-bind="$attrs"
      :name="formName"
      :id="formName"
      @change="change"
      :class="{
        'border-danger': !select2 && !isValid,
        'form-control': true,
        'is-invalid': select2 && !isValid,
        'select2-hidden-accessible': select2 && isMounted,
      }"
    >
      <option v-if="select2"></option>
      <option v-else :disabled="optionDisabled" value>{{ selectOption }}</option>
      <option
        v-if="!keyValue"
        v-for="option in options"
        :key="option[valueProperty]"
        :value="option[valueProperty]"
        :selected="'' + option[valueProperty] === '' + selected"
      >
        {{ option[textProperty] }}
      </option>
      <option
        v-else
        v-for="value in sortedOptions"
        :key="value[0]"
        :value="value[0]"
        :selected="'' + value[0] === '' + selected"
      >
        {{ value[1] }}
      </option>
    </select>
    <span
      class="invalid-feedback"
      style="display: block"
      v-if="!isValid"
      v-text="!requiredValidity ? 'Please provide ' + parsedLabel : 'Please provide a valid ' + parsedLabel"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    formName: String,
    options: Array | Object,
    selected: String,
    valueProp: String,
    textProp: String,
    disabled: Boolean,
    select2: Boolean,
    keyValue: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    isValidating: {
      default: false,
    },
  },
  inheritAttrs: false,
  data() {
    return {
      valueProperty: this.valueProp ?? 'id',
      textProperty: this.textProp ?? 'name',
      isMounted: false,
      inputValidity: false,
      requiredValidity: false,
      optionDisabled: false,
    };
  },
  computed: {
    parsedLabel: function () {
      return this.label.trim().substring(this.label.trim().length - 1) === '*'
        ? this.label
            .trim()
            .substring(0, this.label.length - 1)
            .trim()
        : this.label.trim();
    },
    isValid: function () {
      return this.isValidating && this.isMounted ? this.inputValidity : true;
    },
    selectOption: function () {
      const selectOption = this.$selectOption?.value ?? '';
      return selectOption ? selectOption : '--- Select an option ---';
    },
    sortedOptions: function () {
      if (!this.keyValue) {
        // used only for objects
        return false;
      }

      const sorted = Object.entries(this.options).sort((a, b) => a[1].localeCompare(b[1]));
      return sorted;
    },
  },
  methods: {
    setValidity() {
      this.inputValidity = this.$refs.input_elem.checkValidity();
      this.requiredValidity = !this.$refs.input_elem.validity.valueMissing;
    },
    change: function (event) {
      this.setValidity();
      this.$emit('cg-input', event.target.value);
    },
  },
  mounted() {
    this.isMounted = true;
    const hasRequired = this.$attrs.hasOwnProperty('required');
    this.optionDisabled = hasRequired;
    this.setValidity();

    if (this.select2) {
      $(this.$refs.input_elem)
        .select2({
          placeholder: this.selectOption,
          allowClear: !hasRequired,
          theme: 'bootstrap-5',
          width: '100%',
        })
        .on('change', this.change);
    }
  },
};
</script>
